import type {
  CreateOrder,
  CreateOrderItem,
  CreateOrderOrderItemsInner,
  CreateSku,
  GetSentryConfig200Response,
  LogManufacturingFault,
  ManuallyExpandRequest,
  ManufacturingItem,
  Material,
  Order,
  OrderImportSource,
  OrderItem,
  OrderItemCancelCount,
  OrderOrderItemsInner,
  OrderOrderItemsInnerOrderItemStatusCountsInner,
  PreStockedOrderItemList,
  SentryConfig,
  Sku,
  SkuChildSkusInner,
  StatusUpdate
} from '@/api'
import {
  type ManufacturingFault,
  OrderOrderItemsInnerOrderItemStatusCountsInnerStatusEnum as OrderItemStatusEnum,
  OrderStatusEnum,
  SkuSkuTypeEnum
} from '@/api'
import type { Component, FunctionalComponent } from 'vue'
import csawfApi from '@/csawf-api'
import { errorHandler, logSanitizedError, throwError } from '@/services/ErrorHandler'
import { incrementLoadingItemsUpdatedListener, itemUpdated, orderUpdated } from '@/composables'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import type { MessageType } from '@/components/AlertMessageComponent.vue'
import { download, FileType } from '@/services/DownloadService'

export type LocalOrderItemStatusEnum = OrderItemStatusEnum

export const LocalOrderItemStatusEnum = OrderItemStatusEnum

export const LocalOrderItemStatusEnumOrder: LocalOrderItemStatusEnum[] = [
  LocalOrderItemStatusEnum.ReviewBlocked,
  LocalOrderItemStatusEnum.SkuResolutionFailed,
  LocalOrderItemStatusEnum.UserReview,
  LocalOrderItemStatusEnum.StockCheck,
  LocalOrderItemStatusEnum.AwaitingStock,
  LocalOrderItemStatusEnum.AwaitingManufacturing,
  LocalOrderItemStatusEnum.StagedForNesting,
  LocalOrderItemStatusEnum.AwaitingPostProcessing,
  LocalOrderItemStatusEnum.ReadyForDispatch,
  LocalOrderItemStatusEnum.Complete,
  LocalOrderItemStatusEnum.Cancelled
]

export function statusIsEarlier(
  status1?: LocalOrderItemStatusEnum,
  status2?: LocalOrderItemStatusEnum
): boolean {
  if (!status1 || !status2) return false
  return (
    LocalOrderItemStatusEnumOrder.indexOf(status1) < LocalOrderItemStatusEnumOrder.indexOf(status2)
  )
}

export function stringLabelCompare(
  str: string | String | null | undefined,
  str2: string | String | null | undefined
): boolean {
  if (!str || !str2) return false
  return str.toLowerCase().replace(/[_ -]/g, '') === str2.toLowerCase().replace(/[_ -]/g, '')
}

export function keyVal(object: Object, key: any): any {
  return object[key as keyof typeof object]
}

export function keyValMatch(object1: Object, object2: Object, key: any): any {
  return keyVal(object1, key) === keyVal(object2, key)
}

export type EnumType = { type: String; label: String }

export const activeOrderStatuses: OrderStatusEnum[] = [
  OrderStatusEnum.SkuResolutionFailed,
  OrderStatusEnum.UserReview,
  OrderStatusEnum.Provisioning,
  OrderStatusEnum.ReadyForDispatch
]

export const activeOrderItemStatuses: LocalOrderItemStatusEnum[] = [
  LocalOrderItemStatusEnum.ReviewBlocked,
  LocalOrderItemStatusEnum.SkuResolutionFailed,
  LocalOrderItemStatusEnum.UserReview,
  LocalOrderItemStatusEnum.StockCheck,
  LocalOrderItemStatusEnum.AwaitingStock,
  LocalOrderItemStatusEnum.AwaitingManufacturing,
  LocalOrderItemStatusEnum.StagedForNesting,
  LocalOrderItemStatusEnum.AwaitingPostProcessing,
  OrderStatusEnum.ReadyForDispatch
]

export const preProvisioningOrderItemStatuses: LocalOrderItemStatusEnum[] = [
  LocalOrderItemStatusEnum.ReviewBlocked,
  LocalOrderItemStatusEnum.UserReview
]

export const postProvisioningOrderItemStatuses: LocalOrderItemStatusEnum[] = [
  OrderStatusEnum.ReadyForDispatch,
  OrderStatusEnum.Cancelled,
  OrderStatusEnum.Complete
]

export const finalizedOrderItemStatuses: LocalOrderItemStatusEnum[] = [
  OrderStatusEnum.Cancelled,
  OrderStatusEnum.Complete
]

export const cancelableOrderItemStatuses: LocalOrderItemStatusEnum[] = activeOrderItemStatuses

export const faultableOrderItemStatuses: LocalOrderItemStatusEnum[] = [
  LocalOrderItemStatusEnum.ReviewBlocked,
  LocalOrderItemStatusEnum.SkuResolutionFailed,
  LocalOrderItemStatusEnum.UserReview,
  LocalOrderItemStatusEnum.StockCheck,
  LocalOrderItemStatusEnum.AwaitingStock,
  LocalOrderItemStatusEnum.AwaitingManufacturing
]

export const skuTypes: EnumType[] = [
  { type: 'RESALE', label: 'Resale' },
  { type: 'MANUFACTURED', label: 'Manufactured' },
  { type: 'BUNDLE', label: 'Bundle' }
]

export const designTypes: EnumType[] = [
  { type: 'STATIC', label: 'Static' },
  { type: 'VARIABLE', label: 'Variable' },
  { type: 'CUSTOM', label: 'Custom' }
]

export function getEnumAsArray(enumObj: object): string[] {
  return Object.keys(enumObj).map((key: string) => {
    return enumObj[key as keyof typeof enumObj]
  })
}

export function getEnumFromString(enumObj: object, enumString: string): any | undefined {
  return enumObj[
    (Object.keys(enumObj) as string[]).find(
      (key) =>
        key.toString().replace(/[ _-]/g, '').toLowerCase() ===
        enumString.replace(/[ _-]/g, '').toLowerCase()
    ) as keyof typeof enumObj
  ]
}

export type OrderDialogObject = { order: LocalOrder; userReview?: boolean }

export type FaultDialogObject = { orderItem: LocalOrderItem | LocalOrderItem[] }

export type StockCheckDialogObject = { orderItem: LocalOrderItem; orderItemSkuName?: string }

export type BatchStockCheckDialogObject = { orderItems: LocalOrderItem[] }

export type ItemDialogObject = {
  orderItem: LocalOrderItem
  order: LocalOrder
  items?: LocalOrderItem[]
  skuResolution?: boolean
  rowEndButtons?: any[]
}

export type BatchSkuResolutionDialogObject = {
  orderItems: LocalOrderItem[]
  order?: LocalOrder
  items?: LocalOrderItem[]
  skuResolution?: boolean
  orders?: LocalOrder[]
}

export type ManualExpansionDialogObject = { orderItem: LocalOrderItem; order: LocalOrder }

export type SkuDialogObject = { sku: LocalSku; itemIdAwaitingNewSKU?: number }

export type LocalUser = {
  userName: string
  firstName: string
  lastName: string
  initials?: string
  email?: string
}

export type TableRowEndButton = {
  label: string
  tooltip: string
  action: Function
  displayCheckFunction?: Function | null // EG: button.displayCheckFunction(tableData[index])"     displayFunc(item) { return (item.hasStatus(LocalOrderItemStatusEnum.StagedForNesting)) }
  icon?: Component | null
  getIcon?: Function | null
  obscure?: Function | null
  getLabel?: Function | null
  getToolTip?: Function | null
  name?: string
  class?: string
  dontDisplayInDialog?: boolean
}

export const PossibleFrontendOrderItemStatusChanges: string[] = [
  LocalOrderItemStatusEnum.AwaitingStock,
  LocalOrderItemStatusEnum.StagedForNesting,
  LocalOrderItemStatusEnum.AwaitingPostProcessing,
  LocalOrderItemStatusEnum.Cancelled,
  LocalOrderItemStatusEnum.ReadyForDispatch
]

export interface LocalError extends Error {
  name: string
  message: string
  stack?: string
  then?: Function
}

//expected inputs dd/mm/yyyy dd-mm-yyyy yyyy/mm/dd yyyy-mm-dd
export function isValidDateString(dateString: any): boolean {
  if (typeof dateString !== 'string') return false
  const rxDDMMYYYY =
    /^([1-9]|0[1-9]|[12][0-9]|3[01])[- /.]([1-9]|0[1-9]|1[012])[- /.](19|20)([0-9][0-9])$/g
  const rxYYYYMMDD =
    /^(19|20)([0-9][0-9])[- /.]([1-9]|0[1-9]|1[012])[- /.]([1-9]|0[1-9]|[12][0-9]|3[01])$/g
  return dateString.match(rxDDMMYYYY) !== null || dateString.match(rxYYYYMMDD) !== null
}

class ApiService {
  async getSentryCfg(): Promise<SentryConfig | undefined> {
    try {
      const response: GetSentryConfig200Response | SentryConfig = (await csawfApi.getSentryConfig())
        .data
      return (
        Object.prototype.hasOwnProperty.call(response, 'frontend_sentry_dsn')
          ? response
          : Array.isArray(response.results)
            ? response.results[0]
            : response.results
      ) as SentryConfig
    } catch (e) {
      logSanitizedError(e)
    }
    return {} as SentryConfig
  }

  async importOnlineOrders(): Promise<void> {
    try {
      await csawfApi.runImportOrdersJob('{}', csawfApi.axiosRequestConfigWithToken())
    } catch (e) {
      errorHandler(e)
    }
  }

  async syncCompleteOnlineOrders(): Promise<void> {
    try {
      await csawfApi.runAutoProgressFulfilledOrders('{}', csawfApi.axiosRequestConfigWithToken())
    } catch (e) {
      errorHandler(e)
    }
  }

  async downloadDesignFiles(items: LocalOrderItem[] | LocalOrderItem): Promise<void> {
    if (!Array.isArray(items)) items = [items]
    try {
      const requestObject: DesignFileZipRequest = generateDesignFileZipRequest(items)
      const response: AxiosResponse<any> = await csawfApi.generateDesignFileZipOrderItem(
        requestObject,
        csawfApi.axiosRequestConfigWithTokenAsArrayBuffer()
      )
      download(response.data, items.length.toString() + '-items', FileType.ZIP)
    } catch (e) {
      throwError('Error Requesting Design File Zip Download For (' + items.length + ') Items.', e)
    }
  }

  async getOrder(id: number | string): Promise<LocalOrder> {
    try {
      const order: Order = (
        await csawfApi.retrieveOrder(
          id.toString(),
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          csawfApi.axiosRequestConfigWithToken()
        )
      ).data as Order
      return new LocalOrder(order)
    } catch (e) {
      errorHandler(e)
    }
    return {} as LocalOrder
  }

  async getItem(id: number | string): Promise<LocalOrderItem> {
    try {
      const item: OrderItem = (
        await csawfApi.retrieveOrderItem(
          id.toString(),
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          csawfApi.axiosRequestConfigWithToken()
        )
      ).data as OrderItem
      return new LocalOrderItem(item)
    } catch (e) {
      errorHandler(e)
    }
    return {} as LocalOrderItem
  }

  async getSku(id: number | string): Promise<LocalSku> {
    try {
      const sku: Sku = (
        await csawfApi.retrieveSku(
          id.toString(),
          undefined,
          undefined,
          undefined,
          undefined,
          csawfApi.axiosRequestConfigWithToken()
        )
      ).data as Sku
      return new LocalSku(sku)
    } catch (e) {
      errorHandler(e)
    }
    return {} as LocalSku
  }

  async getOrders(): Promise<LocalOrder[]> {
    return (await this.getOrdersSet()).data
  }

  async getOrdersIn(ids: number[] | string[]): Promise<LocalOrder[]> {
    try {
      const response = await csawfApi.listOrders(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        ids.map((id) => id.toString()).join(','),
        undefined,
        undefined,
        csawfApi.axiosRequestConfigWithToken()
      )
      const orders: Order[] = (response?.data?.results || response?.data || []) as Order[]
      return orders.map((order) => new LocalOrder(order))
    } catch (e) {
      errorHandler(e)
    }
    return [] as LocalOrder[]
  }

  async getOrdersSet(
    num?: number,
    idx?: number,
    search?: string,
    ordering?: string,
    status?: string | OrderStatusEnum[]
  ): Promise<DataSet<LocalOrder>> {
    return await this.getDataSet(
      csawfApi.listOrders,
      num,
      idx,
      search,
      ordering,
      (o: Order) => new LocalOrder(o),
      [undefined, undefined, this.getStatus(status), this.getStatusIn(status)]
    )
  }

  getStatus(status?: string | OrderStatusEnum[]): string | undefined {
    if (
      Array.isArray(status) ||
      ['active statuses', 'all statuses'].some((s) => stringLabelCompare(status, s))
    )
      return undefined
    return status
  }

  getStatusIn(status?: string | OrderStatusEnum[]): string | undefined {
    if (Array.isArray(status)) return status.join(',')
    if (stringLabelCompare(status, 'active statuses')) return activeOrderStatuses.join(',')
    return undefined
  }

  async getSkus(): Promise<LocalSku[]> {
    return (await this.getSkusSet(undefined, undefined, undefined, 'label')).data
  }

  async getSkusIn(ids: number[] | string[]): Promise<LocalSku[]> {
    try {
      if (!ids || ids.length < 1) return []
      if (ids.length > 50) {
        const subSets: Array<string[]> = []
        for (let i = 0; i < ids.length; i += 50) {
          subSets.push(ids.map((id) => id.toString()).slice(i, i + 50))
        }
        return (await Promise.all(subSets.map((ids) => this.getSkusInIDsSubSet(ids)))).flat()
      } else {
        return await this.getSkusInIDsSubSet(ids)
      }
    } catch (e) {
      errorHandler(e)
    }
    return [] as LocalSku[]
  }

  async getSkusInIDsSubSet(ids: number[] | string[]): Promise<LocalSku[]> {
    try {
      const response = await csawfApi.listSkus(
        undefined,
        undefined,
        undefined,
        'label',
        undefined,
        ids.map((id) => id.toString()).join(','),
        csawfApi.axiosRequestConfigWithToken()
      )
      const skus: Sku[] = (response?.data?.results || response?.data || []) as Sku[]
      return skus.map((sku: Sku) => new LocalSku(sku))
    } catch (e) {
      errorHandler(e)
    }
    return [] as LocalSku[]
  }

  async getSkusMatchingLabel(label: string): Promise<Array<LocalSku>> {
    return (await api.getSkusSet(Number.MAX_SAFE_INTEGER, 0, label)).data || []
  }

  async getSkusSet(
    num?: number,
    idx?: number,
    search?: string,
    ordering?: string
  ): Promise<DataSet<LocalSku>> {
    return await this.getDataSet(
      csawfApi.listSkus,
      num,
      idx,
      search,
      ordering,
      (s: Sku) => new LocalSku(s),
      [undefined, undefined]
    )
  }

  async getOrderImportSources(): Promise<OrderImportSource[]> {
    return (await this.getImportSourcesSet()).data
  }

  async getImportSourcesSet(
    num?: number,
    idx?: number,
    search?: string,
    ordering?: string
  ): Promise<DataSet<OrderImportSource>> {
    return await this.getDataSet(
      csawfApi.listOrderImportSources,
      num,
      idx,
      search,
      ordering,
      (s: OrderImportSource) => new LocalOrderImportSource(s),
      [undefined, undefined]
    )
  }

  async getMaterials(): Promise<Material[]> {
    return (await this.getMaterialsSet()).data
  }

  async getMaterialsSet(
    num?: number,
    idx?: number,
    search?: string,
    ordering?: string
  ): Promise<DataSet<Material>> {
    return await this.getDataSet(
      csawfApi.listMaterials,
      num,
      idx,
      search,
      ordering,
      (m: Material) => m,
      [undefined, undefined]
    )
  }

  async getItems(): Promise<LocalOrderItem[]> {
    return (await this.getItemsSet(Number.MAX_SAFE_INTEGER, 0, undefined, 'id')).find(
      (set) => set.modelName === 'order_items'
    )?.data as LocalOrderItem[]
  }

  async getItemsIn(ids: number[] | string[]): Promise<LocalOrderItem[]> {
    try {
      const response = await csawfApi.listOrderItems(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        ids.map((id) => id.toString()).join(','),
        undefined,
        undefined,
        undefined,
        csawfApi.axiosRequestConfigWithToken()
      )
      const items: OrderItem[] = (response?.data?.results || response?.data || []) as OrderItem[]
      return items.map((item) => new LocalOrderItem(item))
    } catch (e) {
      errorHandler(e)
    }
    return [] as LocalOrderItem[]
  }

  async getItemsSet(
    num?: number,
    idx?: number,
    search?: string,
    ordering?: string,
    status?: string | LocalOrderItemStatusEnum[],
    material?: string
  ): Promise<DataSet<LocalOrderItem>[]> {
    if (ordering?.includes('sku')) {
      ordering = ordering.replace('sku', 'sku__label')
    } else if (ordering?.includes('material')) {
      ordering = ordering.replace('material', 'sku__material__label')
    }
    return await this.getMergedModelsDataSet(
      csawfApi.listOrderItems,
      num,
      idx,
      search,
      ordering,
      (i: OrderItem) => new LocalOrderItem(i),
      [
        undefined,
        undefined,
        Array.isArray(status) || stringLabelCompare(status, 'all statuses') ? undefined : status,
        Array.isArray(status) ? status.join(',') : undefined,
        material?.toLowerCase() === 'all materials' ? undefined : material
      ],
      'order_items'
    )
  }

  async getManufacturableItems(): Promise<LocalOrderItem[]> {
    return (await this.getManufacturableItemsSet(undefined, undefined, undefined, 'id')).find(
      (set) => set.modelName === 'order_items'
    )?.data as LocalOrderItem[]
  }

  async getManufacturableItemsSet(
    num?: number,
    idx?: number,
    search?: string,
    ordering?: string,
    status?: string | OrderItemStatusEnum[],
    material?: string
  ): Promise<DataSet<any>[]> {
    const replaceOrdering: { from: string; to: string }[] = [
      { from: 'order', to: 'order_item__order' },
      { from: 'id', to: 'order_item__id' },
      { from: 'created', to: 'order_item__created' },
      { from: 'sku', to: 'order_item__sku__label' },
      { from: 'material', to: 'order_item__sku__material__label' },
      { from: 'custom_text', to: 'order_item__custom_text' },
      { from: 'requires_post_processing', to: 'order_item__requires_post_processing' },
      { from: 'post_processing_notes', to: 'order_item__post_processing_notes' },
      { from: 'manufacturing_notes', to: 'order_item__manufacturing_notes' },
      { from: 'notes', to: 'order_item__notes' }
    ]

    for (const replace of replaceOrdering) {
      if (ordering?.includes(replace.from)) {
        ordering = ordering.replace(replace.from, replace.to)
        break
      }
    }

    ordering = ordering == undefined ? '-order_item__created' : ordering
    if (!ordering.includes('order_item__id')) ordering = ordering + ',-order_item__id'

    return await this.getMergedModelsDataSet(
      csawfApi.listOrderItemStatusCounts,
      num,
      idx,
      search,
      ordering,
      (i: ManufacturingItem) => new LocalOrderItem(i),
      [
        undefined,
        undefined,
        Array.isArray(status) || stringLabelCompare(status, 'all statuses') ? undefined : status,
        Array.isArray(status) ? status.join(',') : undefined,
        stringLabelCompare(material, 'all materials') ? undefined : material
      ],
      'order_items'
    )
  }

  async getFaultsForItemID(id: number | string): Promise<LocalFault[]> {
    return (await this.getFaultsSet(undefined, undefined, undefined, '-created', id.toString()))
      .data
  }

  async getFaults(): Promise<LocalFault[]> {
    return (await this.getFaultsSet()).data
  }

  async getFaultsSet(
    num?: number,
    idx?: number,
    search?: string,
    ordering?: string,
    order_item_id?: number | string
  ): Promise<DataSet<LocalFault>> {
    return await this.getDataSet(
      csawfApi.listManufacturingFaults,
      num,
      idx,
      search,
      ordering,
      (f: ManufacturingFault) => new LocalFault(f),
      [undefined, undefined, order_item_id]
    )
  }

  async getDataSet(
    f: (...args: any[]) => Promise<any>,
    num: number = Number.MAX_SAFE_INTEGER,
    idx: number = 0,
    srch: string | undefined = undefined,
    ordr: string | undefined = undefined,
    dataMapFunc: Function = (i: any) => i,
    args: any[] = []
  ): Promise<DataSet<any>> {
    try {
      const tkn: AxiosRequestConfig = csawfApi.axiosRequestConfigWithToken()
      const result: AxiosResponse<any> = await f.call(csawfApi, num, idx, srch, ordr, ...args, tkn)
      const list: object[] = result.data.results?.map((i: any) => dataMapFunc(i)) || []
      return { data: list, totalNum: result.data.count || 0 }
    } catch (e) {
      errorHandler(e)
    }
    return {} as DataSet<any>
  }

  async getMergedModelsDataSet(
    f: (...args: any[]) => Promise<any>,
    num: number = Number.MAX_SAFE_INTEGER,
    idx: number = 0,
    srch: string | undefined = undefined,
    ordr: string | undefined = undefined,
    dataMapFunc: Function = (i: any) => i,
    args: any[] = [],
    modelsMapFuncModelName: string = ''
  ): Promise<DataSet<any>[]> {
    try {
      const tkn: AxiosRequestConfig = csawfApi.axiosRequestConfigWithToken()
      const result: AxiosResponse<any> = await f.call(csawfApi, num, idx, srch, ordr, ...args, tkn)

      const models: DataSet<any>[] = []
      Object.keys(result.data).forEach((key: any) => {
        const data = result.data[key as keyof typeof result.data]
        let results: Array<any> = data.results || data || []
        if (key === modelsMapFuncModelName) {
          results = results.map((i: any) => dataMapFunc(i))
        }
        models.push({ modelName: key, data: results, totalNum: data.count || results.length })
      })

      return models
    } catch (e) {
      errorHandler(e)
    }
    return [] as DataSet<any>[]
  }
}

export const api = new ApiService()

export const getSkusForDropDown = async (
  maxNumItems: number,
  stringFilter?: string
): Promise<DataSet<LocalSku>> => {
  return await api.getSkusSet(maxNumItems, 0, stringFilter, 'label')
}

export const getInitialSku = async (): Promise<LocalSku> => {
  return new LocalSku((await getSkusForDropDown(1)).data[0])
}

export const getMaterialsForDropDown = async (
  maxNumItems: number,
  stringFilter?: string
): Promise<DataSet<Material>> => {
  return await api.getMaterialsSet(maxNumItems, 0, stringFilter, 'label')
}

export interface LocalOrderImportSource extends OrderImportSource {}

export type additionalFilterObject = {
  filterName: string
  objectFilterPropertyKeyName: string | null
  customObjectFilterFunction?: Function
}

export interface LocalSku extends Sku {}

export class LocalSku implements LocalSku {
  constructor(sku: Sku) {
    this.id = sku.id
    this.created = sku.created
    this.updated = sku.updated
    this.label = sku.label
    this.description = sku.description
    this.sku_type = sku.sku_type
    this.material = sku.material
    this.design_type = sku.design_type
    this.child_skus = sku.child_skus
  }

  async create(): Promise<any> {
    if (this.sku_type !== SkuSkuTypeEnum.Bundle) {
      this.child_skus = []
    }
    if (this.sku_type !== SkuSkuTypeEnum.Manufactured) {
      delete this.design_type
      this.material = {}
    }
    const createSku: any = this
    createSku.material = createSku.material?.id
    return (
      await csawfApi.createSku(createSku as CreateSku, csawfApi.axiosRequestConfigWithToken())
    ).data as any
  }

  async labelAlreadyExists(): Promise<boolean> {
    try {
      const existingSkus: LocalSku[] = await api.getSkusMatchingLabel(this.label)
      const existingSku: LocalSku | undefined = existingSkus.find((s) => s.label === this.label)
      return existingSku !== undefined
    } catch (e) {
      errorHandler(e)
    }
    return false
  }

  noIdCompare(sku: LocalSku): boolean {
    return (
      (this.child_skus ? JSON.stringify(this.child_skus.sort()) : null) ===
        (sku.child_skus ? JSON.stringify(sku.child_skus.sort()) : null) &&
      this.sku_type === sku.sku_type &&
      this.label === sku.label &&
      (this.description || null) === (sku.description || null) &&
      (this.design_type || null) === (sku.design_type || null) &&
      (this.material || null) === (sku.material || null)
    )
  }
}

export class LocalOrderImportSource implements LocalOrderImportSource {
  constructor(orderImportSource: OrderImportSource) {
    this.id = orderImportSource?.id
    this.created = orderImportSource?.created
    this.updated = orderImportSource?.updated
    this.name = orderImportSource?.name
  }
}

export function arrayIncludesStringLike(
  array: Array<any>,
  searchString: string | null | undefined
): boolean {
  if (!array || array.length < 1 || !searchString || searchString.length < 1) return false
  return array?.find(
    (key) =>
      key.toLowerCase().replace(/[_ -]/g, '') === searchString.toLowerCase().replace(/[_ -]/g, '')
  )
}

export class StatusUpdateCount implements StatusUpdate {
  count: number
  constructor(count: number) {
    this.count = count
  }
}

export interface LocalCreateOrderItem extends CreateOrderOrderItemsInner {}

export class LocalCreateOrderItem implements LocalCreateOrderItem {
  constructor(orderItem: LocalOrderItem) {
    Object.assign(this, orderItem) // Only TypeSafe if constructor accepts one type
  }
}

export interface LocalOrderItemStatusCount extends OrderOrderItemsInnerOrderItemStatusCountsInner {}

export class LocalOrderItemStatusCount implements LocalOrderItemStatusCount {
  constructor(orderItemStatusCount: OrderOrderItemsInnerOrderItemStatusCountsInner) {
    Object.assign(this, orderItemStatusCount) // Only TypeSafe if constructor accepts one type
  }

  get isPostProvisioning(): boolean {
    return !!this.status && postProvisioningOrderItemStatuses.includes(this.status)
  }

  get isCancelable(): boolean {
    return !!this.status && cancelableOrderItemStatuses.includes(this.status)
  }

  get isActive(): boolean {
    return !!this.status && activeOrderItemStatuses.includes(this.status)
  }
}

export interface LocalOrderItem extends OrderItem {}

export interface ManufacturableOrderItem extends OrderItem {
  status?: LocalOrderItemStatusEnum
}

export class LocalOrderItem implements LocalOrderItem {
  id?: number
  created?: string
  updated?: string
  order?: number
  sku?: number | null
  order_item_status_counts?: Array<LocalOrderItemStatusCount>
  count?: string
  custom_text?: string | null
  customization_has_been_performed?: boolean
  notes?: string | null
  manufacturing_notes?: string | null
  is_expanded?: boolean
  expanded_from?: number | null
  requires_post_processing?: boolean
  post_processing_notes?: string | null
  material: string | null | undefined
  status?: LocalOrderItemStatusEnum
  unresolved_sku?: string | null
  has_fault?: string
  has_design_file: boolean

  constructor(orderItem: LocalOrderItem | OrderItem | ManufacturingItem | CreateOrderItem) {
    this.id = orderItem.id
    this.created = orderItem.created
    this.updated = orderItem.updated
    this.order = orderItem.order
    this.sku = orderItem.sku
    this.custom_text = orderItem.custom_text
    this.customization_has_been_performed = orderItem.customization_has_been_performed
    this.notes = orderItem.notes
    this.manufacturing_notes = orderItem.manufacturing_notes
    this.is_expanded = orderItem.is_expanded
    this.expanded_from = orderItem.expanded_from
    this.requires_post_processing = orderItem.requires_post_processing
    this.post_processing_notes = orderItem.post_processing_notes
    this.unresolved_sku = orderItem.unresolved_sku
    if (orderItem.count != undefined) this.count = orderItem.count.toString()
    const hasFault: string | undefined = (orderItem as LocalOrderItem).has_fault
    if (hasFault != undefined) {
      this.has_fault = hasFault?.toString().toLowerCase() === 'true' ? hasFault : undefined
    }
    if ((orderItem as LocalOrderItem).status != undefined) {
      this.status = (orderItem as ManufacturingItem).status
    } else if ((orderItem as LocalOrderItem).order_item_status_counts != undefined) {
      this.order_item_status_counts = (
        (orderItem as OrderItem).order_item_status_counts as Array<LocalOrderItemStatusCount>
      ).map((statusCount: LocalOrderItemStatusCount) => new LocalOrderItemStatusCount(statusCount))
    }
    this.has_design_file =
      'has_design_file' in orderItem ? orderItem.has_design_file == true : false
  }

  setOnlyCountStatus(status: LocalOrderItemStatusEnum): void {
    if (this.status != undefined)
      throw new Error(`Order Item ${this.id} Has Multiple Status States, cannot proceed.`)
    if (!this.order_item_status_counts || this.order_item_status_counts.length === 0) {
      this.order_item_status_counts = [
        new LocalOrderItemStatusCount({ status: status, count: this.countAsNumber })
      ]
    } else {
      this.order_item_status_counts[0].status = status
    }
  }

  getOnlyCountStatus(): LocalOrderItemStatusEnum {
    if (
      this.order_item_status_counts == undefined ||
      this.order_item_status_counts[0].status == undefined
    )
      throw new Error(`Order Item ${this.id} Has No Status Count States, cannot proceed.`)
    if (this.order_item_status_counts[1] != undefined)
      throw new Error(`Order Item ${this.id} Has Multiple Status States, cannot proceed.`)
    return this.order_item_status_counts[0].status
  }

  get withEarliestStatusOnly(): LocalOrderItem {
    const item: LocalOrderItem = new LocalOrderItem(this)
    if (this.order_item_status_counts) {
      const earliestCount = this.earliestStatusCountStatus()
      if (!earliestCount) throw new Error('Error in ItemDialog, No earliest status count found.')
      item.status = earliestCount.status
      item.count = earliestCount.count?.toString()
      delete item.order_item_status_counts
    }
    return item
  }

  earliestStatusCountStatus(): OrderOrderItemsInnerOrderItemStatusCountsInner | undefined {
    if (!this.order_item_status_counts) return undefined
    let earlistStatus = this.order_item_status_counts[0]
    this.order_item_status_counts.forEach((status) => {
      if (statusIsEarlier(status.status, earlistStatus.status)) earlistStatus = status
    })
    return earlistStatus
  }

  setMaterial(skus: LocalSku[], materials: Material[]) {
    const sku: LocalSku | undefined = skus.find((sku: LocalSku) => sku.id === this.sku)
    const mat: string | undefined = materials.find(
      (m: Material) => m.id === sku?.material?.id
    )?.label
    this.material = mat && mat.length > 0 ? mat : undefined
  }

  get onlyStatus(): LocalOrderItemStatusEnum | undefined {
    if (this.status) return this.status
    if (this.order_item_status_counts && this.order_item_status_counts.length === 1)
      return this.order_item_status_counts[0].status
    return undefined
  }

  hasStatusOrStatusCount(status: LocalOrderItemStatusEnum): boolean {
    if (this.status != undefined && this.order_item_status_counts != undefined) {
      throw new Error(`Order Item ${this.id} Has Multiple Status States, cannot proceed.`)
    }
    return this.hasStatus(status) || this.hasStatusCountStatus(status)
  }

  get hasStatusReviewBlocked(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.ReviewBlocked)
  }

  get hasStatusUserReview(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.UserReview)
  }

  get hasStatusStagedForNesting(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.StagedForNesting)
  }

  get hasStatusAwaitingPostProcessing(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.AwaitingPostProcessing)
  }

  get hasStatusReadyForDispatch(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.ReadyForDispatch)
  }

  get hasStatusAwaitingManufacturing(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.AwaitingManufacturing)
  }

  get hasStatusStockCheck(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.StockCheck)
  }

  get hasStatusAwaitingStock(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.AwaitingStock)
  }

  get hasStatusSkuResolutionFailed(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.SkuResolutionFailed)
  }

  get hasStatusCancelled(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.Cancelled)
  }

  get hasStatusComplete(): boolean {
    return this.hasStatusOrStatusCount(LocalOrderItemStatusEnum.Complete)
  }

  hasStatusInList(statuses: string[]) {
    const sanitizedStatuses = statuses.map((status) => status.replace(/[_-]/g, ' ').toLowerCase())
    const sanitizedStatus = this.status?.replace(/[_-]/g, ' ').toLowerCase() || ''
    return sanitizedStatuses.includes(sanitizedStatus)
  }

  nextStatus(status: LocalOrderItemStatusEnum | undefined = this.onlyStatus): string {
    switch (status) {
      case LocalOrderItemStatusEnum.AwaitingManufacturing:
        return LocalOrderItemStatusEnum.StagedForNesting
      case LocalOrderItemStatusEnum.StagedForNesting:
        if (this.requires_post_processing) {
          return LocalOrderItemStatusEnum.AwaitingPostProcessing
        } else {
          return LocalOrderItemStatusEnum.ReadyForDispatch
        }
      case LocalOrderItemStatusEnum.AwaitingPostProcessing:
        return LocalOrderItemStatusEnum.ReadyForDispatch
      default:
        new Error(
          'Error retrieving next Order Item Status (Only Status For Item ' +
            this.id +
            ' = ' +
            JSON.stringify(status) +
            ')'
        )
        return ''
    }
  }

  get statuses(): LocalOrderItemStatusEnum[] | undefined {
    if (this.status) return [this.status]
    const statusCountStatuses: LocalOrderItemStatusEnum[] | undefined =
      this.order_item_status_counts
        ?.map((statusCount: LocalOrderItemStatusCount) => statusCount.status)
        .filter((s) => s != undefined)
    return statusCountStatuses && statusCountStatuses.length > 0 ? statusCountStatuses : undefined
  }

  get statusAndCountOrStatusCount(): LocalOrderItemStatusCount[] {
    if (this.order_item_status_counts) return this.order_item_status_counts
    return [new LocalOrderItemStatusCount({ status: this.status, count: this.countAsNumber })]
  }

  get provisioningStatusCounts(): LocalOrderItemStatusCount[] {
    return this.statusAndCountOrStatusCount.filter((sc) => sc.isActive)
  }

  get provisioningStatusCountsTotalCount(): number {
    return this.provisioningStatusCounts.reduce((num, sc) => num + (sc.count ?? 1), 0)
  }

  get statusCountsOrCount(): number {
    return this.statusAndCountOrStatusCount.reduce((num, sc) => num + (sc.count ?? 1), 0)
  }

  get cancelableStatusCounts(): LocalOrderItemStatusCount[] {
    return this.statusAndCountOrStatusCount.filter(
      (sc: LocalOrderItemStatusCount) => sc.isCancelable
    )
  }

  get cancelableStatusCountsOrCount(): number {
    return this.cancelableStatusCounts.reduce((num, sc) => num + (sc.count ?? 1), 0)
  }

  get hasRemainingUncanceledStatusesOrCountsOfStatuses(): boolean {
    return this.cancelableStatusCounts.length > 1 || this.cancelableStatusCountsOrCount > 1
  }

  get isInMultipleStatuses(): boolean {
    return this.statusAndCountOrStatusCount.length > 1
  }

  get isPostProvisioning(): boolean {
    return this.allStatusesAreIn(postProvisioningOrderItemStatuses)
  }

  get isFinalized(): boolean {
    return this.allStatusesAreIn(finalizedOrderItemStatuses)
  }

  get isPreProvisioning(): boolean {
    return this.someStatusesAreIn(preProvisioningOrderItemStatuses)
  }

  get isAbleToBeCanceled(): boolean {
    return this.someStatusesAreIn(activeOrderItemStatuses)
  }

  someStatusesAreIn(statuses: LocalOrderItemStatusEnum[]): boolean {
    return !!this.statuses && this.statuses.some((s: OrderItemStatusEnum) => statuses.includes(s))
  }

  allStatusesAreIn(statuses: LocalOrderItemStatusEnum[]): boolean {
    return !!this.statuses && this.statuses.every((s: OrderItemStatusEnum) => statuses.includes(s))
  }

  get isNew(): boolean {
    const hasCreatedDate: boolean = (this.created && this.created.length > 0) || false
    return !hasCreatedDate
  }

  async update(propagate: boolean = true): Promise<void> {
    if (!this.requires_post_processing && this.post_processing_notes) {
      this.post_processing_notes = null
    }
    await csawfApi.partialUpdateOrderItem(
      (this.id || '').toString(),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this,
      csawfApi.axiosRequestConfigWithToken()
    )
    await this.hasBeenUpdated(propagate)
  }

  async updateWithResolvedSku(propagate: boolean = true): Promise<any> {
    // NOTE: to resolve the SKU, you need to update OrderItem.unresolved_sku to the LABEL of the desired SKU, then run retrySkuResolutionOrderItem, you do not need to try to updated the actual OrderItem.sku
    try {
      await this.update(false)
      await this.retryResolveSku(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async retryResolveSku(propagate: boolean = true): Promise<any> {
    try {
      await csawfApi.retrySkuResolutionOrderItem(
        (this.id || '').toString(),
        this,
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async cancel(propagate: boolean = true): Promise<void> {
    await csawfApi.cancelOrderItem(
      (this.id || '').toString(),
      this,
      csawfApi.axiosRequestConfigWithToken()
    )
    await this.hasBeenUpdated(propagate)
  }

  async cancelWithCount(
    sc?: LocalOrderItemStatusCount,
    cancelEntireItem: boolean = false,
    propagate: boolean = true
  ): Promise<void> {
    if (cancelEntireItem) return await this.cancel(propagate)
    if (!sc || !sc.status || !sc.count) throw new Error('Invalid StatusCount Cancelation Request.')
    try {
      const cancelStatusCount: OrderItemCancelCount = { status: sc.status, count: sc.count }
      await csawfApi.cancelCountOrderItem(
        (this.id || '').toString(),
        cancelStatusCount,
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async getAssociatedOrder(): Promise<any> {
    try {
      return (
        await csawfApi
          .retrieveOrder(
            (this.order || '').toString(),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            csawfApi.axiosRequestConfigWithToken()
          )
          .then((response) => {
            return response
          })
      ).data as any
    } catch (e) {
      errorHandler(e)
    }
  }

  async stagedForNesting(count?: number, propagate: boolean = true): Promise<any> {
    // Customization applies to all "item status counts" / "products", as they use the same design file
    try {
      count = count ?? this.countOfStatus(LocalOrderItemStatusEnum.AwaitingManufacturing)
      await csawfApi.markStagedForNestingOrderItem(
        (this.id || '').toString(),
        new StatusUpdateCount(count),
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async inStock(count?: number, propagate: boolean = true): Promise<any> {
    try {
      if (this.status === LocalOrderItemStatusEnum.AwaitingStock) {
        await this.stockReceived(count, propagate)
      } else {
        count = count ?? this.countOfStatus(LocalOrderItemStatusEnum.StockCheck)
        await csawfApi.markInStockOrderItem(
          (this.id || '').toString(),
          new StatusUpdateCount(count),
          csawfApi.axiosRequestConfigWithToken()
        )
        await this.hasBeenUpdated(propagate)
      }
    } catch (e) {
      errorHandler(e)
    }
  }

  async stockReceived(count?: number, propagate: boolean = true): Promise<any> {
    try {
      count = count ?? this.countOfStatus(LocalOrderItemStatusEnum.AwaitingStock)
      await csawfApi.markStockReceivedOrderItem(
        (this.id || '').toString(),
        new StatusUpdateCount(count),
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async awaitingStock(count?: number, propagate: boolean = true): Promise<any> {
    try {
      count = count ?? this.countOfStatus(LocalOrderItemStatusEnum.StockCheck)
      await csawfApi.markAwaitingStockOrderItem(
        (this.id || '').toString(),
        new StatusUpdateCount(count),
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async customizationPerformed(propagate: boolean = true): Promise<any> {
    try {
      await csawfApi.markCustomizationPerformedOrderItem(
        (this.id || '').toString(),
        this,
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async progress(
    status: LocalOrderItemStatusEnum,
    count?: number,
    propagate: boolean = true
  ): Promise<any> {
    // Note: check customization has been performed before using this
    switch (status) {
      case LocalOrderItemStatusEnum.AwaitingManufacturing: {
        return this.stagedForNesting(count, propagate)
      }
      case LocalOrderItemStatusEnum.StagedForNesting: {
        return this.readyForDispatch(count, propagate)
      }
      case LocalOrderItemStatusEnum.AwaitingPostProcessing: {
        return this.postProcessed(count, propagate)
      }
    }
    return new Promise((resolve, reject) => {
      reject(new Error('OrderItem is not in a state to be progressed'))
    })
  }

  async readyForDispatch(count?: number, propagate: boolean = true): Promise<any> {
    // if order_item.requires_post_processing, OrderItemStatusType.AWAITING_POST_PROCESSING
    // else OrderItemStatusType.READY_FOR_DISPATCH
    try {
      count = count ?? this.countOfStatus(LocalOrderItemStatusEnum.StagedForNesting)
      await csawfApi.markCutSuccessfulOrderItem(
        (this.id || '').toString(),
        new StatusUpdateCount(this.validateCount(count)),
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async postProcessed(count?: number, propagate: boolean = true): Promise<any> {
    try {
      count = count ?? this.countOfStatus(LocalOrderItemStatusEnum.AwaitingPostProcessing)
      await csawfApi.markPostProcessingSuccessfulOrderItem(
        (this.id || '').toString(),
        new StatusUpdateCount(this.validateCount(count)),
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async logFault(
    description: string,
    count: number = 1,
    fromStatus: LocalOrderItemStatusEnum = this.getFromStatus(),
    propagate: boolean = true
  ): Promise<any> {
    try {
      // Note, status is automatically changed to AWAITING_MANUFACTURING
      const item = await csawfApi.logManufacturingFaultOrderItem(
        (this.id || '').toString(),
        new LocalLogManufacturingFault({
          description: description,
          count: count,
          from_status: fromStatus
        }),
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
      return item
    } catch (e) {
      errorHandler(e)
    }
  }

  getFromStatus(): LocalOrderItemStatusEnum {
    return (
      this.status ||
      (this.order_item_status_counts && this.order_item_status_counts.length === 1
        ? this.order_item_status_counts[0].status
        : undefined) ||
      LocalOrderItemStatusEnum.ReviewBlocked
    )
  }

  validateCount(count?: number): number {
    count = count ? count : this.count && !isNaN(+this.count) ? +this.count : undefined
    if (count === undefined) throw new Error(`Count For Item ${this.id} Is Not Set.`)
    return count
  }

  validateStatusCount(status: string | LocalOrderItemStatusEnum): number {
    const statusCountCount: number | undefined = this.order_item_status_counts?.find((sc) =>
      stringLabelCompare(sc.status, status)
    )?.count
    if (!statusCountCount) {
      throw new Error(
        `Order Item Status Counts For Item ${this.id} Does Not Contain Provided Status (${status})`
      )
    }
    return statusCountCount
  }

  validateStatus(status: string | LocalOrderItemStatusEnum): void {
    if (!this.hasStatus(status)) {
      throw new Error(
        `Order Item ${this.id} Status ${this.status} does not match provided Status ${status}`
      )
    }
  }

  countOfStatus(status: string | LocalOrderItemStatusEnum): number {
    if (this.status != undefined) {
      this.validateStatus(status)
      return this.validateCount()
    }
    return this.validateStatusCount(status)
  }

  hasStatus(status: string | LocalOrderItemStatusEnum): boolean {
    return stringLabelCompare(this.status, status)
  }

  hasStatusCountStatus(status: string | LocalOrderItemStatusEnum): boolean {
    return !!this.order_item_status_counts?.some((sc) => sc.status === status)
  }

  hasCountGreaterThan(count: number): boolean {
    return this.count !== undefined && !isNaN(+this.count) && +this.count > count
  }

  get hasCount(): boolean {
    return this.hasCountGreaterThan(0)
  }

  get countAsNumber(): number {
    if (!this.hasCount) throw new Error('OrderItem count is not set')
    return Number(this.count)
  }

  async manuallyExpand(
    childSkus: { child_sku: LocalSku | Sku; count?: number }[],
    propagate: boolean = true
  ): Promise<any> {
    try {
      const expandChildSkus: SkuChildSkusInner[] = []
      childSkus.forEach((sku: { child_sku: LocalSku | Sku; count?: number }): void => {
        expandChildSkus.push({ child_sku: sku.child_sku.id || -1, count: sku.count || 1 })
      })
      const manuallyExpandRequest: ManuallyExpandRequest = { child_skus: expandChildSkus }
      await csawfApi.manuallyExpandOrderItem(
        (this.id || -1).toString(),
        manuallyExpandRequest,
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.retrieveAndEmitRefreshedItemOrder()
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async retrieveAndEmitRefreshedItemOrder(): Promise<void> {
    try {
      const orderResponse: any = await csawfApi.retrieveOrder(
        (this.order || '').toString(),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        csawfApi.axiosRequestConfigWithToken()
      )
      orderUpdated(new LocalOrder(orderResponse.data))
    } catch (e) {
      errorHandler(e)
    }
  }

  async retrieveAndEmitRefreshedOrderItem(): Promise<void> {
    try {
      const response: any = await csawfApi.retrieveOrderItem(
        (this.id || '').toString(),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        csawfApi.axiosRequestConfigWithToken()
      )
      itemUpdated(new LocalOrderItem(response.data))
    } catch (e) {
      errorHandler(e)
    }
  }

  async hasBeenUpdated(propagate: boolean = true): Promise<any> {
    if (propagate) {
      await this.retrieveAndEmitRefreshedOrderItem()
    }
    incrementLoadingItemsUpdatedListener()
  }
}

export interface LocalFault extends ManufacturingFault {}

export class LocalFault implements LocalFault {
  constructor(fault: ManufacturingFault) {
    this.id = fault.id
    this.created = fault.created
    this.updated = fault.updated
    this.description = fault.description
    this.order_item = fault.order_item
    this.order = fault.order
  }

  async update(): Promise<any> {
    try {
      return (
        await csawfApi.partialUpdateManufacturingFault(
          (this.id || '').toString(),
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          this,
          csawfApi.axiosRequestConfigWithToken()
        )
      ).data as any
    } catch (e) {
      errorHandler(e)
    }
  }
}

export interface LocalLogManufacturingFault extends LogManufacturingFault {}

export class LocalLogManufacturingFault implements LocalLogManufacturingFault {
  constructor(fault: LogManufacturingFault) {
    Object.assign(this, fault) // Only TypeSafe if constructor accepts one type
  }
}

export interface LocalOrder extends Order {
  order_items?: Array<LocalOrderItem>
}

export class LocalOrder implements LocalOrder {
  constructor(order: Order | LocalOrder) {
    this.id = order?.id
    this.created = order?.created
    this.date = order?.date
    this.updated = order?.updated
    this.customer_name = order?.customer_name
    this.notes = order?.notes
    this.order_import_source = order?.order_import_source
    this.source_order_display_id = order?.source_order_display_id
    this.status = order?.status
    this.order_items = []
    if (order?.order_items) {
      order.order_items?.forEach((item: OrderOrderItemsInner): void => {
        this.order_items?.push(new LocalOrderItem(item))
      })
    }
  }

  get hasStatusCancelled(): boolean {
    return this.hasStatus(OrderStatusEnum.Cancelled)
  }

  hasStatus(status: string) {
    return (
      this.status?.replace(/[_-]/g, ' ').toLowerCase() ===
        status.replace(/[_-]/g, ' ').toLowerCase() || false
    )
  }

  nextStatus(): string {
    switch (this.status) {
      case OrderStatusEnum.InitError:
        return OrderStatusEnum.UserReview
      case OrderStatusEnum.UserReview:
        return OrderStatusEnum.Provisioning
      case OrderStatusEnum.Provisioning:
        return OrderStatusEnum.ReadyForDispatch
      case OrderStatusEnum.ReadyForDispatch:
        return OrderStatusEnum.Complete
      default:
        return ''
    }
  }

  hasStatusInList(statuses: string[]) {
    const sanitizedStatuses = statuses.map((status) => status.replace(/[_-]/g, ' ').toLowerCase())
    const sanitizedStatus = this.status?.replace(/[_-]/g, ' ').toLowerCase() || ''
    return sanitizedStatuses.includes(sanitizedStatus)
  }

  async update(propagate: boolean = true): Promise<LocalOrder> {
    const response = await csawfApi.partialUpdateOrder(
      (this.id || '').toString(),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this,
      csawfApi.axiosRequestConfigWithToken()
    )
    await this.hasBeenUpdated(propagate)
    return new LocalOrder(response.data)
  }

  async addItem(item: LocalOrderItem, propagate: boolean = true): Promise<LocalOrderItem> {
    try {
      const count: number = item.count && !isNaN(+item.count) ? +item.count : 1
      const newItem: CreateOrderItem = { ...item, count: count } as CreateOrderItem
      newItem.order = this.id || -1
      newItem.id = undefined
      return new LocalOrderItem(
        await csawfApi
          .createOrderItem(newItem, csawfApi.axiosRequestConfigWithToken())
          .then(async (response) => {
            await this.hasBeenUpdated(propagate)
            return response.data
          })
      )
    } catch (e) {
      errorHandler(e)
    }
    return {} as LocalOrderItem
  }

  getItem(id: number | undefined): LocalOrderItem | undefined {
    if (!id || !this.order_items) {
      return undefined
    }
    const item: LocalOrderItem | CreateOrderOrderItemsInner | undefined = this.order_items?.find(
      (orderItem: OrderOrderItemsInner): boolean => orderItem.id === id
    )
    return item ? new LocalOrderItem(item) : undefined
  }

  get cancelableItems(): LocalOrderItem[] {
    return this.order_items?.filter((i) => i.isAbleToBeCanceled) || []
  }

  get unFinalizedItems(): LocalOrderItem[] {
    return this.order_items?.filter((i) => !i.isFinalized) || []
  }

  get finalizedItems(): LocalOrderItem[] {
    return this.order_items?.filter((i) => i.isFinalized) || []
  }

  async cancel(propagate: boolean = true): Promise<LocalOrder> {
    await csawfApi.cancelOrder(
      (this.id as number).toString(),
      this,
      csawfApi.axiosRequestConfigWithToken()
    )
    await this.hasBeenUpdated(propagate)
    return await api.getOrder(this.id as number)
  }

  async create(orderItems: LocalOrderItem[], propagate: boolean = true): Promise<any> {
    const newCreateOrderObject: CreateOrder = {
      ...(this as Order),
      order_items: orderItems.map((i: LocalOrderItem) => new LocalCreateOrderItem(i))
    }
    delete this.status
    this.id = (
      await csawfApi.createOrder(newCreateOrderObject, csawfApi.axiosRequestConfigWithToken())
    ).data.id
    await this.hasBeenUpdated(propagate)
  }

  async userReviewed(
    prestocked: { item: LocalOrderItem; count: number }[],
    propagate: boolean = true
  ): Promise<void> {
    // Sends Order and All Order Items to Provisioning
    if (!prestocked.every((psi) => this.order_items?.map((i) => i.id).includes(psi.item.id))) {
      throw new Error('Invalid Order Items Provided For User Review.')
    }
    const preStockedOrderItemList: PreStockedOrderItemList = {
      prestocked_order_items: prestocked.map((psi) => ({
        id: psi.item.id || -1,
        count: psi.count
      }))
    }
    await csawfApi.sendToProvisioningOrder(
      (this.id || '').toString(),
      preStockedOrderItemList,
      csawfApi.axiosRequestConfigWithToken()
    )
    await this.hasBeenUpdated(propagate)
  }

  async complete(propagate: boolean = true): Promise<any> {
    try {
      await csawfApi.completeOrder(
        (this.id || '').toString(),
        this,
        csawfApi.axiosRequestConfigWithToken()
      )
      await this.hasBeenUpdated(propagate)
    } catch (e) {
      errorHandler(e)
    }
  }

  async retrieveAndEmitRefreshedOrder(): Promise<void> {
    try {
      const response: any = await csawfApi.retrieveOrder(
        (this.id || '').toString(),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        csawfApi.axiosRequestConfigWithToken()
      )
      const orderItems: LocalOrderItem[] = (response.data.order_items as LocalOrderItem[]) || []
      orderItems.forEach((orderItem: LocalOrderItem): void => {
        itemUpdated(new LocalOrderItem(orderItem))
      })
      orderUpdated(new LocalOrder(response.data))
    } catch (e) {
      errorHandler(e)
    }
  }

  async hasBeenUpdated(propagate: boolean = true): Promise<void> {
    if (propagate) {
      await this.retrieveAndEmitRefreshedOrder()
    }
    incrementLoadingItemsUpdatedListener()
  }
}

export interface Filter {
  objectPropertyKeyName: string
  propertyFilterValues: string[]
  activeFilterIndex: number
  listFilterable?: boolean | undefined
  showAllFilterIndex: number | null
}

export class Filter implements Filter {
  constructor(
    objectPropertyKeyName: string,
    propertyFilterValues: string[],
    activeFilterIndex: number,
    listFilterable?: boolean,
    showAllFilterIndex: number | null = null
  ) {
    this.objectPropertyKeyName = objectPropertyKeyName
    this.propertyFilterValues = propertyFilterValues
    this.activeFilterIndex = activeFilterIndex
    this.listFilterable = listFilterable
    this.showAllFilterIndex = showAllFilterIndex
  }

  currentFilter(): string {
    return this.propertyFilterValues[this.activeFilterIndex]
  }
  equivalentActiveFilterIndex(filter: Filter): number | undefined {
    const oldV: string | undefined = this.currentFilter()
    const newActiveIndex = oldV ? filter.propertyFilterValues.indexOf(oldV) : -1
    return newActiveIndex > -1 ? newActiveIndex : undefined
  }
}

export const mapFilters = (filters: Filter[]): Filter[] => {
  return filters.map((filter: Filter) => {
    return new Filter(
      filter.objectPropertyKeyName,
      filter.propertyFilterValues,
      filter.activeFilterIndex,
      filter.listFilterable,
      filter.showAllFilterIndex
    )
  })
}

export const capitalize = (s: string | String | undefined): string => {
  if (!s) return ''
  return s.toString().charAt(0).toUpperCase() + s.toString().substring(1)
}

export const capitalizeFirstLetters = (s: string | String | undefined): string => {
  if (!s) return ''
  return s.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())
}

export type DataSet<T> = { data: T[]; totalNum: number; modelName?: string }

export interface AbortablePromise {
  abortController: AbortController
  func: Function
  then: Function
}

export class AbortablePromise implements AbortablePromise {
  constructor() {
    this.abortController = new AbortController()
    this.func = (): void => {}
    this.then = (): void => {}
  }

  async abortablePromise(signal: AbortSignal, asyncFunc: Function, then: Function): Promise<any> {
    const response = await asyncFunc()
    if (signal.aborted) return
    return then(response)
  }

  setFunc(func: Function): void {
    this.func = func
  }

  setThen(then: Function): void {
    this.then = then
  }

  execute(): Promise<any> {
    return this.abortablePromise(this.abortController.signal, this.func, this.then)
  }

  abort(reason?: string): void {
    this.abortController.abort(reason || 'Aborted By Controller.')
    this.abortController = new AbortController()
  }

  resetAndExecute(func: Function, then: Function): Promise<any> {
    this.abort('New Request From Controller.')
    this.setFunc(func)
    this.setThen(then)
    return this.execute()
  }
}

export interface ExpandableTab {
  index?: number
  expanded: boolean
  setShown: Function
}

export class ExpandableTab implements ExpandableTab {
  constructor(expanded: boolean, setShown: Function, index?: number) {
    this.expanded = expanded
    this.setShown = setShown
    this.index = index
  }

  hasIndex(index?: number): boolean {
    if (!index) return false
    return this.index === index
  }
}

export interface CollapsableRowGroupIcon {
  displayCheck: Function
  getToolTip?: Function
  iconClass?: string
  iconComponent: object | FunctionalComponent<any>
}

export class CollapsableRowGroupIcon implements CollapsableRowGroupIcon {
  constructor(rowGroupIcon: CollapsableRowGroupIcon) {
    this.displayCheck = rowGroupIcon.displayCheck
    this.getToolTip = rowGroupIcon.getToolTip
    this.iconClass = rowGroupIcon.iconClass
    this.iconComponent = rowGroupIcon.iconComponent
  }
}

export interface CancelItemCountReturnType {
  confirmed: boolean
  cancelEntireItem: boolean
  statusCount: LocalOrderItemStatusCount | undefined
}

export class CancelItemCountReturnType implements CancelItemCountReturnType {
  constructor(cancelItemCountReturnType: CancelItemCountReturnType) {
    Object.assign(this, cancelItemCountReturnType) // Only TypeSafe if constructor accepts one type
  }
}

export interface AlertMessage {
  message: string
  messageType?: MessageType
}

export class AlertMessage implements AlertMessage {
  constructor(alertMessage: AlertMessage) {
    Object.assign(this, alertMessage) // Only TypeSafe if constructor accepts one type
  }
}

export type DesignFileZipRequestInner = {
  order_item_id: number
  status: LocalOrderItemStatusEnum
}

export type DesignFileZipRequest = Array<DesignFileZipRequestInner>

export function generateDesignFileZipRequest(items: LocalOrderItem[]): DesignFileZipRequest {
  return items.map((item: LocalOrderItem): DesignFileZipRequestInner => {
    if (item.onlyStatus == undefined)
      throw new Error('All Items Must Have A Discreet Status To Generate Design File Zip Request')
    if (item.id == undefined)
      throw new Error('All Items Must Have An ID To Generate Design File Zip Request')
    return { order_item_id: item.id, status: item.onlyStatus }
  })
}
