<template>
  <dialog-content
    :dialog-title="dialogTitle"
    :buttons="[
      { label: 'Cancel', onClick: closeDialog },
      {
        label: 'Out Of Stock',
        onClick: () => {
          setStock(false)
        },
        condition:
          !(item && item.hasStatusAwaitingStock) &&
          !(items && items.some((i) => i.hasStatusAwaitingStock))
      },
      {
        label: 'Stock Available',
        onClick: () => {
          setStock(true)
        }
      }
    ]"
  >
    <div v-if="hasCount">
      <number-input
        v-model:number="count"
        label="Stock Available: "
        :editable="true"
        :min="1"
        :max="maxCount"
      />
    </div>
    <div v-else>
      <p v-if="item && items.length < 1" class="text-sm text-gray-500 pb-4">
        Confirm Stock Availability For Order Item{{ item.id ? ': ' + item.id : ''
        }}{{ name ? ' (' + name + ')' : '' }}
      </p>
      <p v-if="!item && items.length > 0" class="text-sm text-gray-500 pb-4">
        Confirm Stock Availability For {{ items.length }} Order Items
      </p>
    </div>
  </dialog-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { throwError } from '@/services/ErrorHandler'
import { itemsUpdated, showSpinner } from '@/composables'
import {
  type BatchStockCheckDialogObject,
  LocalOrderItem,
  type StockCheckDialogObject
} from '@/services/DataServices'
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'
import { setGlobalUpdatingFlag } from '@/main'
import NumberInput from '@/components/inputComponents/NumberInput.vue'

export default defineComponent({
  name: 'ViewStockCheckDialogContent',
  emits: ['update:dialogOpen'],
  props: {
    dialogOpen: {
      // Receives dialogOpen value from v-model:dialog-open="dialogOpen"
      type: Boolean,
      required: true,
      default: true
    },
    stockCheckProp: {
      type: Object as PropType<object | null>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: null as LocalOrderItem | null,
      items: [] as LocalOrderItem[],
      name: undefined as string | undefined,
      count: undefined as number | undefined,
      maxCount: 5 as number
    }
  },
  computed: {
    dialogTitle() {
      return this.item
        ? 'Set Stock Available For Order Item ' + (this.item.id || '')
        : 'Set Stock Available For ' +
            (this.items && this.items.length > 1
              ? this.items.length + ' Order Items'
              : 'Order Item')
    },
    hasCount(): boolean {
      return this.count !== undefined
    }
  },
  methods: {
    async setStock(setInStock: boolean) {
      setGlobalUpdatingFlag(true)
      if (this.item) {
        showSpinner(true)
        await this.setStockForSingleItem(setInStock, this.item)
      } else {
        showSpinner(true, this.items.length)
        await this.setStockForMultipleItems(setInStock, this.items)
      }
      setGlobalUpdatingFlag(false)
      showSpinner(false)
      this.closeDialog(this.item || this.items[0])
    },
    async setStockForSingleItem(setInStock: boolean, item: LocalOrderItem) {
      if (setInStock) {
        await item.inStock(this.count)
      } else {
        await item.awaitingStock(this.count)
      }
    },
    async setStockForMultipleItems(setInStock: boolean, items: LocalOrderItem[]) {
      if (setInStock) {
        for (const item of items) {
          await item.inStock()
        }
      } else {
        for (const item of items) {
          await item.awaitingStock()
        }
      }
    },
    closeDialog(item?: LocalOrderItem) {
      // Updates Users Local OrderItem Object
      if (item) itemsUpdated()
      this.$emit('update:dialogOpen', false)
    }
  },
  created() {
    // On page load (after DOM)
    try {
      if ((this.stockCheckProp as any).orderItem !== undefined) {
        this.item = new LocalOrderItem((this.stockCheckProp as StockCheckDialogObject).orderItem)
        this.name = (this.stockCheckProp as StockCheckDialogObject).orderItemSkuName
      } else {
        this.items = (this.stockCheckProp as BatchStockCheckDialogObject)
          .orderItems as LocalOrderItem[]
      }
    } catch (e) {
      throwError('Error in StockCheckDialog props', e)
      this.closeDialog()
    }
  },
  components: {
    NumberInput,
    DialogContent
  }
})
</script>
