export function isIsoDateString(str: any): boolean {
  if (typeof str !== 'string') return false
  return (
    new RegExp(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}/).test(str) && !isNaN(Date.parse(str))
  )
}

export function processHumanReadableDate(
  date?: string,
  returnDateOnly?: boolean,
  locale?: string,
  currentDateOverride?: string
): string {
  return new humanReadableDateProcessor(date, returnDateOnly, locale, currentDateOverride).process()
}

class humanReadableDateProcessor {
  private readonly inputDate: string
  private readonly returnDateOnly: boolean
  private blankStringForUseInTableFields: string = ''
  private readonly dateIsUTC: boolean = false
  private readonly todayIsUTC: boolean = false

  constructor(
    inputDate?: string,
    returnDateOnly?: boolean,
    private locale?: string,
    private currentDateOverride?: string
  ) {
    this.inputDate = inputDate ?? ''
    this.returnDateOnly = returnDateOnly == true
    this.dateIsUTC = this.inputDate?.toUpperCase().includes('Z')
    this.todayIsUTC = !!this.currentDateOverride?.toUpperCase().includes('Z')
  }

  public process(): string {
    if (this.inputDate.length == 0) return this.blankStringForUseInTableFields
    else if (this.returnDateOnly || this.isFutureDate) return this.dateAsString
    else return this.descriptiveDateString
  }

  get descriptiveDateString(): string {
    if (this.isTheSameMinute) return this.secondsAgoString
    else if (this.isTheSameHour) return this.minutesAgoString
    else if (this.isInTheSameDay) return this.hoursAgoString
    else if (this.wasYesterday) return 'Yesterday'
    else return this.dateAsString
  }

  get date(): Date {
    return new Date(this.inputDate)
  }

  get today(): Date {
    return new Date(this.currentDateOverride || new Date().toISOString())
  }

  get isFutureDate(): boolean {
    return this.date.getTime() > this.today.getTime()
  }

  get dateAsString(): string {
    const options: { timeZone?: string } = { timeZone: this.dateIsUTC ? 'UTC' : undefined }
    return new Date(this.inputDate).toLocaleDateString(this.locale, options)
  }

  get isInTheSameYear(): boolean {
    return this.date.getFullYear() === this.today.getFullYear()
  }

  get monthOfToday(): number {
    return this.todayIsUTC ? this.today.getUTCMonth() : this.today.getMonth()
  }

  get monthOfDate(): number {
    return this.dateIsUTC ? this.date.getUTCMonth() : this.date.getMonth()
  }

  get isInTheSameMonth(): boolean {
    return this.isInTheSameYear && this.monthOfDate === this.monthOfToday
  }

  get isInTheSameDay(): boolean {
    return this.isInTheSameMonth && this.date.getDate() === this.today.getDate()
  }

  get hoursOfToday(): number {
    return this.todayIsUTC ? this.today.getUTCHours() : this.today.getHours()
  }

  get minutesOfToday(): number {
    return this.todayIsUTC ? this.today.getUTCMinutes() : this.today.getMinutes()
  }

  get secondsOfToday(): number {
    return this.todayIsUTC ? this.today.getUTCSeconds() : this.today.getSeconds()
  }

  get milisOfToday(): number {
    return this.todayIsUTC ? this.today.getUTCMilliseconds() : this.today.getMilliseconds()
  }

  get msSinceStartOfYesterday(): number {
    const oneDayMs: number = 24 * 60 * 60 * 1000
    const todayHrsMs: number = this.hoursOfToday * 60 * 60 * 1000
    const todayMinMs: number = this.minutesOfToday * 60 * 1000
    const todaySecMs: number = this.secondsOfToday * 1000
    return oneDayMs + todayHrsMs + todayMinMs + todaySecMs + this.milisOfToday
  }

  get wasYesterday(): boolean {
    const startOfYesterday: number = this.today.getTime() - this.msSinceStartOfYesterday
    return !this.isInTheSameDay && this.date.getTime() >= startOfYesterday
  }

  get isTheSameHour(): boolean {
    const oneHourAgo: number = this.today.getTime() - 60 * 60 * 1000
    return (
      this.isInTheSameDay &&
      this.date.getTime() > oneHourAgo &&
      this.date.getTime() - oneHourAgo > 59999
    )
  }

  get isTheSameMinute(): boolean {
    const oneMinuteAgo: number = this.today.getTime() - 60 * 1000
    return (
      this.isTheSameHour &&
      this.date.getTime() > oneMinuteAgo &&
      this.date.getTime() - oneMinuteAgo > 999
    )
  }

  get secondsAgoString(): string {
    let secondsAgo: number = this.today.getSeconds() - this.date.getSeconds()
    secondsAgo = secondsAgo < 0 ? secondsAgo + 60 : secondsAgo
    return secondsAgo + ' ' + (secondsAgo === 1 ? 'second' : 'seconds') + ' ago'
  }

  get minutesAgoString(): string {
    let minutesAgo: number = this.today.getMinutes() - this.date.getMinutes()
    minutesAgo = minutesAgo < 0 ? minutesAgo + 60 : minutesAgo
    return minutesAgo + ' ' + (minutesAgo === 1 ? 'minute' : 'minutes') + ' ago'
  }

  get hoursAgoString(): string {
    let hoursAgo: number = this.today.getHours() - this.date.getHours()
    hoursAgo = hoursAgo < 0 ? hoursAgo + 24 : hoursAgo
    return hoursAgo + ' ' + (hoursAgo === 1 ? 'hour' : 'hours') + ' ago'
  }
}
