<template>
  <div class="overflow-auto h-full">
    <div class="lg:px-3 xl:px-5 2xl:px-7 overflow-visible h-full">
      <d-table
        :filters-prop="filters"
        :enable-show-groups-checkbox="
          showGroupRowsByFilterCheckBox &&
          (!columnToGroupExclusively || (!!columnToGroupExclusively && !!exclusiveGroupKey))
        "
        :column-to-group-exclusively="exclusiveGroupKey?.toLowerCase().replace(/_/g, ' ')"
        :display-row-groups="displayRowGroups"
        :show-text-input-filter="displayTextInputFilter !== false || !!stringFilter"
        :num-table-rows="sortedTableData?.length || 0"
        :paginate="totalPages > 1 && paginate && !displayRowGroups"
        :total-pages="totalPages"
        v-model:current-page-number="currentPageNumber"
        @update:filters="filterBy($event)"
        @update:display-row-groups="setDisplayRowGroups($event)"
        @update:filter-by-text="filterByString($event)"
        @update:clear-filters="clearAllFiltersIfRequired()"
        :loading="sortedTableData && sortedTableData[0] && loading"
      >
        <table-head v-if="tableData && tableData[0] && Object.keys(tableData[0]).length !== 0">
          <!-- TOP LEFT CHECKBOX -->
          <table-head-cell
            class="text-center px-1 table-header-white backdrop-blur-[2px] mr-1 backdrop-f top-0 sticky w-0 capitalize cursor-pointer whitespace-break-spaces"
            v-if="displayCheckBoxes && sortedTableData && sortedTableData[1]"
            @click="selectAll()"
          >
            <input
              data-testid="select-all-checkbox"
              type="checkbox"
              class="my-2 sm:my-0 p-[clamp(0.6rem,_3vw,_1rem)] sm:p-0 cursor-pointer left-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              :checked="allChecked"
            />
          </table-head-cell>

          <!-- TABLE COLUMN HEADERS -->
          <table-head-cell
            class="table-header-text mr-1 sm:mr-0 m-0 sm:m-2 table-header-white backdrop-blur-[2px] top-0 sticky text-center text-xs sm:text-sm capitalize cursor-pointer whitespace-break-spaces"
            :class="{ 'table-header-grey': activeColumn === header }"
            v-for="header in Object.keys(sortedTableData[0] || tableData[0])"
            :key="header"
            :hide-cells="hiddenFields"
            :small-screen-hidden-fields="smallScreenHiddenFields"
            :cell="header"
            @click="sortBy(header)"
            v-tooltip="'Sort by ' + processColumnHeaderFunction(header.replace(/_/g, ' '))"
          >
            {{ processColumnHeaderFunction(header.replace(/_/g, ' ')) }}
          </table-head-cell>

          <!-- TOP RIGHT BUTTON -->
          <table-head-cell
            v-if="rowEndTopButtons && rowEndButtons?.length > 0"
            class="table-header-white-no-highlight backdrop-blur-[2px] top-0 sticky text-center capitalize p-0 w-0"
            :style-override="'padding: 0.1rem;'"
          >
            <div class="flex justify-end">
              <div
                class="flex whitespace-nowrap items-center max-w-fit w-fit py-0 px-0 self-baseline sm:self-auto mb-1 sm:mb-[-0.5rem] my-[-0.5rem]"
                v-for="(rowEndTopButton, index) in rowEndTopButtons as TableRowEndButton[]"
                :key="'rowEndTopButton' + index"
                :class="rowEndTopButton.class || ''"
              >
                <button
                  v-tooltip="
                    rowEndTopButton?.getToolTip
                      ? rowEndTopButton.getToolTip(selectedRowObjects)
                      : rowEndTopButton?.tooltip
                  "
                  :key="selectedRows.length"
                  v-if="
                    rowEndTopButton.displayCheckFunction
                      ? rowEndTopButton.displayCheckFunction(selectedRowObjects)
                      : false
                  "
                  type="button"
                  @click="rowEndButtonClick($event, rowEndTopButton, selectedRowObjects)"
                  class="mx-0.5 sm:mx-1 w-auto flex rounded-md px-1.5 py-1.5 text-sm font-semibold shadow bg-indigo-600 hover:bg-indigo-400 text-white hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  :class="[
                    {
                      'opacity-10 shadow-[inset_0_0_10rem_black]':
                        rowEndTopButton?.obscure && rowEndTopButton?.obscure(selectedRowObjects)
                    },
                    {
                      shadow:
                        rowEndTopButton?.obscure === undefined ||
                        rowEndTopButton?.obscure === null ||
                        !rowEndTopButton?.obscure(selectedRowObjects)
                    }
                  ]"
                >
                  {{
                    rowEndTopButton?.getLabel
                      ? rowEndTopButton?.getLabel(selectedRowObjects)
                      : rowEndTopButton?.label
                  }}
                  <component
                    v-if="rowEndTopButton?.getIcon || rowEndTopButton?.icon"
                    :is="
                      rowEndTopButton.getIcon
                        ? rowEndTopButton.getIcon(selectedRowObjects)
                        : rowEndTopButton?.icon
                    "
                    class="h-[clamp(1.6rem,_7vw,_2rem)] sm:h-7 w-[clamp(1.6rem,_7vw,_2rem)] sm:w-7 m-[-0.25rem]"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </table-head-cell>
        </table-head>

        <!-- FILTER RETURNED NO RESULTS -->
        <table-body
          class="h-10"
          v-if="
            !(sortedTableData && sortedTableData[0]) ||
            (tableData && Object.keys(tableData[0]).length === 0)
          "
        >
          <tr class="bg-slate-600/[0.05] select-none">
            <td colspan="100%">
              <p
                class="w-full text-center text-sm text-gray-500 pb-3 sm:pb-5 pt-4 sm:pt-6"
                :class="{ loading: loading }"
              >
                {{ loading ? 'Loading' : 'No Results' }}
              </p>
            </td>
          </tr>
        </table-body>

        <!-- FILTERABLE/SORTABLE RESULTS -->
        <table-body v-if="sortedTableData && sortedTableData[0]">
          <!-- TABLE-ROW-SET ALLOWS FOR INFINITE SCROLLING -->
          <table-row-set
            :visible-row-set="visibleRowSet"
            @update:visible-row-set="visibleRowSet = $event as number"
            v-for="(rowSet, setIndex) in sortedTableDataSets as any[]"
            :index="setIndex"
            :key="'row-group-set-' + setIndex"
          >
            <!-- GROUP ROWS BY ACTIVE COLUMN -->
            <collapsable-row-groups
              v-for="(rowGroup, groupIndex) in groupedTableDataSets[setIndex] as any[]"
              :display-row-groups="displayRowGroups"
              :table-rows="rowGroup"
              :group-by="activeColumn || ''"
              :group-by-value="getRowGroupValue(rowGroup)"
              :collapsed="hiddenRowGroups.includes(groupIndex)"
              :key="'row-group-' + groupIndex"
              :display-check-boxes="displayCheckBoxes && sortedTableData.length > 1"
              :group-selected="rowGroupSelected(rowGroup)"
              @update:show-group="expandCollapseRowGroup(groupIndex, $event as boolean)"
              @update:select-group="selectDeselectRowGroup(rowGroup, $event as boolean)"
              :collapsable-icon="collapsableRowsIconObject"
            >
              <!-- TABLE ROWS -->
              <table-row
                :data-testid="`table-row_${uniqueKey}-${sortedTableData[index][uniqueKey as keyof typeof row]}`"
                v-for="(row, index) in rowGroup as any[]"
                :key="'row-' + index"
                :show="!hiddenRowGroups.includes(groupIndex)"
                :highlighted="highlightedRows?.includes(row)"
                :selected-rows="selectedRows"
                :selected-index="sortedTableData.indexOf(row)"
              >
                <!-- CHECKBOX CELLS -->
                <table-cell
                  class="text-center mr-1 w-0 sm:text-center capitalize cursor-pointer hover:bg-slate-600/[0.1] whitespace-break-spaces"
                  v-if="displayCheckBoxes && sortedTableData && sortedTableData[1]"
                  @click="selectRow(row)"
                  :style-override="'padding: 0;'"
                >
                  <input
                    type="checkbox"
                    class="p-[clamp(0.6rem,_3vw,_1rem)] sm:p-0 m-0 sm:m-2 sm:m0 cursor-pointer left-4 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    :value="row"
                    :checked="!!selectedRows[sortedTableData?.indexOf(row)]"
                  />
                </table-cell>

                <!-- DATA CELLS -->
                <table-cell
                  class="table-text"
                  v-for="(value, key) in row"
                  :key="key"
                  :first-cell="key.toString() === 'id'"
                  @click="clickFunction ? clickFunction(getOriginal(row)) : () => {}"
                  :class="[
                    'text-center',
                    {
                      'cursor-pointer': clickFunctionExists
                    },
                    {
                      'bg-slate-600/[0.1]': activeColumn === key.toString()
                    },
                    {
                      'super-slim-field': !(
                        fieldVal(row, key.toString()) &&
                        typeof fieldVal(row, key.toString()) === 'object'
                      )
                    }
                  ]"
                  :hide-cells="hiddenFields"
                  :small-screen-hidden-fields="smallScreenHiddenFields"
                  :cell="key.toString()"
                >
                  <!-- Allows custom components to be piped into table fields returned as {component: definedComponentClassName, propObject: propDataObject} -->
                  <component
                    v-if="typeof fieldVal(row, key.toString()) === 'object'"
                    :is="fieldVal(row, key.toString()).component"
                    :propObject="fieldVal(row, key.toString())?.propObject || {}"
                    style="height: 100%"
                    class="table-data-text"
                  />
                  <span v-else class="table-data-text">
                    {{ fieldVal(row, key.toString()) }}
                  </span>
                </table-cell>

                <!-- ROW END BUTTON CELLS -->
                <table-cell
                  @click="clickFunction ? clickFunction(getOriginal(row)) : () => {}"
                  :key="'cell-button-' + row[uniqueKey as keyof typeof row]"
                  v-if="rowEndButtons?.length > 0"
                  :class="{ 'cursor-pointer': clickFunctionExists }"
                  class="table-cell whitespace-nowrap max-w-fit w-fit"
                  :style-override="'padding: 0.1rem;'"
                >
                  <div class="flex justify-end">
                    <div
                      v-tooltip="highlightedIcon?.tooltip"
                      class="mx-0.5 w-[clamp(1.5rem,_5vw,_2rem)] sm:w-5 sm:mx-1 rounded-md py-1.5"
                      :class="
                        highlightedIcon &&
                        highlightedRows?.length > 0 &&
                        (highlightedRows?.includes(row) || false)
                          ? 'flex'
                          : 'hidden'
                      "
                    >
                      <component
                        class="self-end"
                        v-if="highlightedIcon?.iconComponent"
                        :is="highlightedIcon.iconComponent"
                        :class="highlightedIcon.class || ''"
                      />
                    </div>
                    <div
                      v-for="(button, buttonIndex) in rowEndButtons as TableRowEndButton[]"
                      :key="'button' + index + '' + buttonIndex"
                      class="flex whitespace-nowrap items-center max-w-fit w-fit py-0.5"
                      :class="button.class || ''"
                    >
                      <button
                        v-tooltip="button.tooltip"
                        v-if="
                          button.displayCheckFunction
                            ? button.displayCheckFunction(getOriginal(row))
                            : false
                        "
                        type="button"
                        @click="rowEndButtonClick($event, button, getOriginal(row))"
                        class="mx-0.5 sm:mx-1 w-auto flex rounded-md px-1.5 py-1.5 text-sm font-semibold shadow bg-neutral-100 hover:bg-neutral-300 text-neutral-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-400"
                      >
                        {{ button.label }}
                        <component
                          v-if="button.icon"
                          :is="button.icon"
                          class="h-[clamp(1.6rem,_7vw,_2rem)] sm:h-7 w-[clamp(1.6rem,_7vw,_2rem)] sm:w-7 m-[-0.25rem]"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </table-cell>
              </table-row>
            </collapsable-row-groups>
          </table-row-set>
        </table-body>
      </d-table>
    </div>
  </div>
</template>

<script lang="ts">
import TableHeadCell from '@/components/Table/TableHeadCell.vue'
import TableCell from '@/components/Table/TableCell.vue'
import TableBody from '@/components/Table/TableBody.vue'
import TableRow from '@/components/Table/TableRow.vue'
import TableHead from '@/components/Table/TableHead.vue'
import CollapsableRowGroups from '@/components/Table/CollapsableRowGroups.vue'
import DTable from '@/components/Table/DTable.vue'
import { defineComponent, nextTick, type PropType } from 'vue'
import {
  Filter,
  stringLabelCompare,
  mapFilters,
  AbortablePromise,
  keyValMatch,
  CollapsableRowGroupIcon
} from '@/services/DataServices'
import type { additionalFilterObject, DataSet } from '@/services/DataServices'
import type { TableRowEndButton } from '@/services/DataServices'
import { showToolTip } from '@/composables'
import { throwError } from '@/services/ErrorHandler'
import TableRowSet from '@/components/Table/TableRowSet.vue'
import { getGlobalUpdatingFlag } from '@/main'
import { isIsoDateString, processHumanReadableDate } from '@/services/DateTimeServices.ts'

export default defineComponent({
  name: 'TableComponent',
  components: {
    TableRowSet,
    TableHeadCell,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    DTable,
    CollapsableRowGroups
  },
  computed: {
    selectedRowObjects() {
      return this.selectedRows.filter((row) => !!row)
    },
    allChecked() {
      return this.selectedRows.every((row) => !!row)
    },
    sortedTableDataSets() {
      if (this.displayRowGroups || this.sortedTableData?.length < 1) return [this.sortedTableData]
      let returnArray = []
      for (let i = 0; i < Math.ceil(this.sortedTableData.length / this.rowSetSize); i++) {
        const setSize = (i + 1) * this.rowSetSize
        const sortedSize = this.sortedTableData.length
        const endIndex = setSize > sortedSize ? sortedSize : setSize
        returnArray.push(this.sortedTableData.slice(i * this.rowSetSize, endIndex))
      }
      return returnArray
    },
    groupedTableDataSets(): any[] {
      // Returns An Array (Of Row-Sets) Of Arrays (Of Row-Groups) Of Rows
      if (this.displayRowGroups) {
        // Processing Date Prevents Date Objects Being Grouped By Millisecond
        return this.sortedTableDataSets.map((rowSet) => {
          const groupBy = this.activeColumn || 0
          const uniqueGroupedKeys = [
            ...new Set(
              rowSet.map((row) =>
                isIsoDateString(row[groupBy])
                  ? processHumanReadableDate(row[groupBy])
                  : row[groupBy]
              )
            )
          ]
          return uniqueGroupedKeys.map((key) => {
            return rowSet.filter(
              (row) =>
                (isIsoDateString(row[groupBy])
                  ? processHumanReadableDate(row[groupBy])
                  : row[groupBy]) === key
            )
          })
        })
      }
      return [this.sortedTableDataSets]
    }
  },
  props: {
    retrieveTableDataFunction: {
      type: Function as PropType<
        (
          maxNumItems: number,
          startIndex: number,
          stringFilter?: string,
          orderBy?: string,
          filterObjectArray?: Filter[]
        ) => Promise<DataSet<any>>
      >,
      required: true
    },
    updateOnChange: {
      type: null as any,
      required: false,
      default: null
    },
    maxRowSetSize: {
      type: Number as PropType<number>,
      required: false,
      default: Number.MAX_SAFE_INTEGER
    },
    uniqueKey: {
      type: String,
      required: true
    },
    processFieldFunction: {
      type: Function,
      default: (arg: any) => arg // Return unprocessed value if no processing function provided
    },
    processColumnHeaderFunction: {
      type: Function,
      default: (arg: any) => arg // Return unprocessed value if no processing function provided
    },
    clickFunction: {
      type: Function || null,
      default: null
    },
    hiddenFields: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    smallScreenHiddenFields: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    rowEndButtons: {
      type: Array as PropType<TableRowEndButton[]>,
      default: () => []
    },
    rowEndTopButtons: {
      type: Object as PropType<TableRowEndButton[]>
    },
    displayCheckBoxes: {
      type: Boolean,
      default: false
    },
    longTextFieldKeys: {
      // Fields to replace with a text icon with tooltip for text content - EG; notes fields
      type: Array as PropType<string[]>,
      default: () => ['notes']
    },
    defaultFilter: {
      type: Object as PropType<{ objectPropertyKeyName: string; objectPropertyValue: string }>
    },
    filtersProp: {
      type: Array as PropType<Filter[]>,
      default: () => [] as Filter[]
    },
    additionalFilterOptions: {
      type: Array as PropType<additionalFilterObject[]>
    },
    highlightedRows: {
      type: Array as PropType<any[]>,
      default: () => [] as any[]
    },
    highlightedIcon: {
      type: Object as PropType<{
        iconComponent: object
        class: string | undefined
        tooltip: string | undefined
      }>
    },
    showGroupRowsByFilterCheckBox: {
      type: Boolean,
      default: false
    },
    collapseRowGroupsByDefault: {
      type: Boolean,
      default: true
    },
    columnToGroupExclusively: {
      type: String
    },
    displayTextInputFilter: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined
    },
    paginate: {
      type: Boolean,
      default: true
    },
    maxPageLength: {
      type: Number as PropType<number>,
      required: false,
      default: 100
    },
    defaultActiveColumn: {
      type: String,
      required: false
    },
    defaultDescending: {
      type: Boolean as PropType<boolean | undefined>,
      required: false
    },
    collapsableRowsIconObject: {
      type: Object as PropType<CollapsableRowGroupIcon>
    }
  },
  setup(props) {
    const clickFunctionExists: Boolean = props.clickFunction !== null
    return {
      clickFunctionExists
    }
  },
  data() {
    return {
      tableData: [] as any[],
      sortedTableData: [] as any[],
      activeColumn: null as string | null,
      orderDescending: true as boolean,
      selectedRows: [] as any[],
      filters: [] as Filter[],
      visibleRowSet: 0 as number,
      hiddenRowGroups: [] as number[],
      displayRowGroups: false as boolean,
      exclusiveGroupKey: null as string | null,
      rowSetSize: 1 as number,
      stringFilter: undefined as string | undefined,
      currentPageNumber: 1 as number,
      totalPages: 1 as number,
      loading: false as boolean,
      abortablePromise: new AbortablePromise() // Allows aborting promises to prevent races
    }
  },
  methods: {
    clearAllFiltersIfRequired() {
      if (!this.filtersAreCleared()) {
        this.clearFilters()
        this.retrieveTableData()
      }
    },
    clearFilters(): void {
      this.stringFilter = undefined
      let filters = mapFilters(this.filters)
      filters.forEach((filter) => (filter.activeFilterIndex = filter.showAllFilterIndex || 0))
      this.filters = filters
    },
    filtersAreCleared(): boolean {
      const textFilterCleared: boolean = this.stringFilter === undefined
      const filtersCleared: boolean = this.filters.every(
        (filter) => filter.activeFilterIndex === (filter.showAllFilterIndex || 0)
      )
      return textFilterCleared && filtersCleared
    },
    filterByString(filter: string | null | undefined) {
      if (filter !== this.stringFilter) {
        this.stringFilter = filter && filter.length > 0 ? filter : undefined
        this.retrieveTableData()
      }
    },
    filterBy(filters: Filter[]) {
      if (filters && JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = filters
        this.retrieveTableData()
      }
    },
    fieldVal(row: object, key: string) {
      try {
        if (!this.sortedTableData || (this.sortedTableData && this.sortedTableData.length < 1)) {
          // Prevent duplicate errors on loops (after sortedTableData has been set to [])
          return ''
        }
        const getDateStringIfDate: string = this.processDate(row[key as keyof typeof row] || '')
        return this.processFieldFunction(getDateStringIfDate, key, row) || ''
      } catch (e) {
        this.sortedTableData = []
        const currentValue: string =
          row && key && row[key as keyof typeof row]
            ? row[key as keyof typeof row]
            : row
              ? JSON.stringify(row)
              : 'undefined'
        const message: string = '\nCurrent Field Value is "' + currentValue + '"'
        throwError('Table Error', 'Error processing table field values.' + message, e)
      }
      return ''
    },
    sortBy(headerArg?: string, descending?: boolean) {
      if (
        !!this.columnToGroupExclusively &&
        !stringLabelCompare(headerArg, this.exclusiveGroupKey)
      ) {
        // Hide row groups if columnToGroupExclusively is set and not equal to selected column
        this.setDisplayRowGroups(false)
      }
      if (
        this.sortedTableData[0] === undefined ||
        Object.keys(this.sortedTableData[0]).length < 1
      ) {
        return
      }
      const header: string =
        Object.keys(this.sortedTableData[0]).find(
          (key) => key.toLowerCase() === headerArg?.toLowerCase()
        ) ||
        this.uniqueKey ||
        Object.keys(this.sortedTableData[0])[0]

      if (this.activeColumn === header && descending === undefined) {
        this.orderDescending = !this.orderDescending
      } else {
        this.orderDescending = !(descending === false)
      }
      this.activeColumn = header
      this.retrieveTableData()
    },
    getOriginal(td: any) {
      const uK = this.uniqueKey
      return this.sortedTableData.find((sortedTableDataValue) => {
        if (keyValMatch(sortedTableDataValue, td, uK)) {
          const tableDataKeyDuplicates = this.sortedTableData.filter((d) => keyValMatch(d, td, uK))
          if (tableDataKeyDuplicates.length === 1) return true
          return Object.keys(td).every((key) => td[key] === sortedTableDataValue[key])
        }
      })
    },
    processDate: function (arg: any) {
      return arg instanceof Date ? arg.toLocaleDateString() : arg
    },
    selectRow(row: any) {
      this.selectedRows[this.sortedTableData.indexOf(row)] = this.selectedRows?.includes(row)
        ? undefined
        : row
    },
    selectAll(event?: Event) {
      if (event) {
        ;(event.target as HTMLInputElement).checked ? this.selectEvery() : this.selectNone()
      } else {
        this.selectedRowObjects.length === this.sortedTableData.length
          ? this.selectNone()
          : this.selectEvery()
      }
    },
    selectEvery() {
      this.selectedRows = [...this.sortedTableData]
    },
    selectNone() {
      this.selectedRows = this.sortedTableData.map(() => null)
    },
    rowEndButtonClick(event: any, buttonObject?: TableRowEndButton, rowObject?: any) {
      if (buttonObject) {
        buttonObject.action(rowObject)
      }
      event.stopPropagation()
      showToolTip(false)
    },
    expandCollapseRowGroup(groupIndex: number, show: boolean): void {
      if (show) {
        if (this.hiddenRowGroups.indexOf(groupIndex) > -1) {
          this.hiddenRowGroups = this.hiddenRowGroups.filter((x) => x !== groupIndex)
        }
      } else {
        if (!this.hiddenRowGroups.includes(groupIndex)) {
          this.hiddenRowGroups.push(groupIndex)
        }
      }
    },
    selectDeselectRowGroup(grp: any[], slct: boolean): void {
      grp.forEach(
        (r) => (this.selectedRows[this.sortedTableData.indexOf(r)] = slct ? r : undefined)
      )
    },
    async setDisplayRowGroups(display: boolean) {
      // Displays Table Row Groups
      this.displayRowGroups = display
      if (display) {
        await this.retrieveTableData(false)
        this.activeColumn =
          this.activeColumn || this.uniqueKey || Object.keys(this.sortedTableData[0])[0]
        if (this.columnToGroupExclusively && this.exclusiveGroupKey) {
          // Sort by exclusiveGroupKey if columnToGroupExclusively is set
          this.sortBy(this.exclusiveGroupKey)
        }
        if (this.collapseRowGroupsByDefault) {
          this.collapseAllRowGroups()
        }
      } else {
        this.rowSetSize = this.paginate ? this.maxPageLength : this.maxRowSetSize
        this.expandAllRowGroups()
        await this.retrieveTableData()
      }
    },
    expandAllRowGroups() {
      this.hiddenRowGroups = []
    },
    collapseAllRowGroups() {
      for (let groupIndex = 0; groupIndex < this.groupedTableDataSets.length; groupIndex++) {
        for (let i = 0; i < this.groupedTableDataSets[groupIndex].length; i++) {
          this.hiddenRowGroups.push(i)
        }
      }
    },
    getRowGroupValue(rowGroup: any[]): string {
      if (this.displayRowGroups) {
        return (
          typeof this.fieldVal(rowGroup[0], this.activeColumn || '0') !== 'object'
            ? Object.prototype.toString.call(rowGroup[0]) === '[object Date]'
              ? this.processDate(rowGroup[0][this.activeColumn || '0'])
              : this.fieldVal(rowGroup[0], this.activeColumn || '0')
            : rowGroup[0][this.activeColumn || 0] || ''
        ).toString()
      }
      return ''
    },
    rowGroupSelected(rowGroup: any[]): boolean {
      if (!this.displayRowGroups) return false
      return rowGroup.every((row: any) => this.selectedRows.includes(row))
    },
    refreshFilters() {
      // Create or Update Filters
      if (this.filtersProp && this.filtersProp.length > 0) {
        const filters = mapFilters(this.filtersProp)
        // Add default and additional filters
        filters.forEach((filter: Filter) => {
          const existingFilter = this.filters.find(
            (f) => f.objectPropertyKeyName === filter.objectPropertyKeyName
          )
          if (existingFilter) {
            if (
              JSON.stringify(existingFilter?.propertyFilterValues) !==
              JSON.stringify(filter?.propertyFilterValues)
            ) {
              // Filter Exists And Is Changed
              this.filters[this.filters.indexOf(existingFilter)] = this.instantiateFilter(filter)
            }
          } else {
            // Filter Doesn't Exist
            this.filters.push(this.instantiateFilter(filter))
          }
        })
      }
    },
    instantiateFilter(filter: Filter): Filter {
      let newFilter = filter
      if (this.additionalFilterOptions && this.additionalFilterOptions.length > 0) {
        this.additionalFilterOptions.some((additionalFilter: additionalFilterObject) => {
          if (additionalFilter.objectFilterPropertyKeyName === newFilter.objectPropertyKeyName) {
            newFilter.propertyFilterValues.unshift(additionalFilter.filterName)
          }
        })
      }
      const propertyKeyName: string =
        'All ' +
        (newFilter.objectPropertyKeyName?.slice(-1) === 's'
          ? newFilter.objectPropertyKeyName + 'es'
          : newFilter.objectPropertyKeyName + 's')
      newFilter.propertyFilterValues.unshift(propertyKeyName)
      newFilter.showAllFilterIndex = filter?.showAllFilterIndex || 0

      const oldFilter = this.filters.find(
        (f: Filter) => f.objectPropertyKeyName === newFilter.objectPropertyKeyName
      )
      newFilter.activeFilterIndex = oldFilter?.equivalentActiveFilterIndex(newFilter) || 0

      if (
        this.defaultFilter &&
        newFilter.objectPropertyKeyName === this.defaultFilter.objectPropertyKeyName
      ) {
        newFilter.activeFilterIndex = newFilter.propertyFilterValues.indexOf(
          this.defaultFilter.objectPropertyValue
        )
      }
      return newFilter
    },
    resetFilters() {
      this.filters.forEach((filter: Filter) => {
        filter.activeFilterIndex = filter.showAllFilterIndex || 0
      })
    },
    async retrieveTableData(paginate: boolean = true) {
      this.loading = true
      await nextTick()
      if (!this.activeColumn) {
        this.activeColumn =
          this.defaultActiveColumn || this.uniqueKey || Object.keys(this.sortedTableData[0])[0]
        this.orderDescending = this.defaultDescending === undefined ? true : this.defaultDescending
      }
      const indexOffset: number = (this.currentPageNumber - 1) * this.rowSetSize || 0
      const numItems: number =
        paginate && !this.displayRowGroups ? this.rowSetSize || 1 : this.maxRowSetSize
      const stringFilter: string | undefined = this.stringFilter || undefined
      let orderBy: string | undefined = undefined
      if (this.activeColumn) {
        orderBy = this.orderDescending ? '-' + this.activeColumn : this.activeColumn
      }
      let filters: Filter[] | undefined = this.filters.length > 0 ? this.filters : undefined
      const func = async () =>
        await this.retrieveTableDataFunction(numItems, indexOffset, stringFilter, orderBy, filters)
      const next = (returnData: DataSet<any>) => {
        this.totalPages = Math.ceil(returnData.totalNum / this.rowSetSize)
        if (this.totalPages !== 0 && this.currentPageNumber > this.totalPages) {
          // Updating currentPageNumber will cause data to be re-requested at the appropriate offset
          this.currentPageNumber = this.totalPages >= 1 ? this.totalPages : 1
          return
        }
        this.tableData = returnData.data
        nextTick().then(() => (this.loading = false))
      }
      await this.abortablePromise.resetAndExecute(func, next)
    }
  },
  watch: {
    paginate: {
      handler: function (newValue: boolean) {
        this.rowSetSize = newValue && newValue ? this.maxPageLength : this.maxRowSetSize
      },
      immediate: true
    },
    maxRowSetSize: {
      handler: function (newValue: number) {
        this.rowSetSize = this.paginate ? this.maxPageLength : newValue
      },
      immediate: true
    },
    updateOnChange: {
      handler: async function (newVal, oldVal) {
        if (
          !getGlobalUpdatingFlag() &&
          newVal !== undefined &&
          newVal !== null &&
          ((Array.isArray(newVal) && newVal.length > 0) || newVal !== oldVal)
        ) {
          await this.retrieveTableData()
        }
      },
      deep: true,
      immediate: true
    },
    currentPageNumber: {
      handler: async function (newPage, oldPage) {
        if (newPage !== oldPage) {
          await this.retrieveTableData()
        }
      }
    },
    filtersProp: {
      handler: function () {
        this.refreshFilters()
      },
      deep: true,
      immediate: true
    },
    tableData: {
      handler: function () {
        this.sortedTableData = [...new Set(this.tableData)]

        // Default to no selected rows for new data
        this.selectNone()

        // Setup Row Grouping Row Key
        if (this.columnToGroupExclusively && this.tableData && this.tableData[0]) {
          this.exclusiveGroupKey = null
          Object.keys(this.tableData[0]).forEach((key: string) => {
            if (stringLabelCompare(key, this.columnToGroupExclusively)) {
              this.exclusiveGroupKey = key
            }
          })
          if (!this.exclusiveGroupKey) {
            throwError('Error During Table Row Grouping Setup', 'Row Grouping Will Be Unavailable.')
          }
        }
      },
      deep: true,
      immediate: true
    },
    sortedTableData: {
      handler: async function () {
        this.visibleRowSet = 0
        this.selectNone()
      },
      deep: true,
      immediate: true
    },
    groupedTableDataSets: {
      handler: function () {
        if (this.collapseRowGroupsByDefault && this.displayRowGroups) {
          // Prevents only row groups present in previous column/filter/row-group-set being collapsed
          this.collapseAllRowGroups()
        } else if (!this.collapseRowGroupsByDefault && this.displayRowGroups) {
          this.expandAllRowGroups()
        }
      },
      deep: true,
      immediate: true
    }
  }
})
</script>

<style scoped>
.table-header-white-no-highlight {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(255, 255, 255, 0.9) 60%,
    rgba(255, 255, 255, 0.95) 80%,
    rgba(255, 255, 255, 1) 100%
  );
}

.table-header-white {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(255, 255, 255, 0.9) 60%,
    rgba(255, 255, 255, 0.95) 80%,
    rgba(255, 255, 255, 1) 100%
  );
}

.table-header-white:hover {
  background: linear-gradient(
    0deg,
    rgba(236, 238, 240, 0.8) 0%,
    rgba(236, 238, 240, 0.9) 60%,
    rgba(236, 238, 240, 0.95) 80%,
    rgba(236, 238, 240, 1) 100%
  );
}

.table-header-grey {
  background: linear-gradient(
    0deg,
    rgba(236, 238, 240, 0.8) 0%,
    rgba(236, 238, 240, 0.9) 60%,
    rgba(236, 238, 240, 0.95) 80%,
    rgba(236, 238, 240, 1) 100%
  );
}

.table-header-grey:hover {
  background: linear-gradient(
    0deg,
    rgba(219, 221, 226, 0.8) 0%,
    rgba(219, 221, 226, 0.9) 60%,
    rgba(219, 221, 226, 0.95) 80%,
    rgba(219, 221, 226, 1) 100%
  );
}

.table-header-text {
}

.super-slim-field {
}

.table-text {
  max-width: 20rem;
  width: fit-content;
  overflow-wrap: break-word;
  word-break: keep-all;
  white-space: normal;
}

.table-data-text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 2.5rem;
}

@media (max-width: 640px) {
  .table-header-text {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 3rem;
  }

  .table-text {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 480px) {
  .table-header-text {
    font-size: 11px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 2rem;
  }

  .table-text {
    font-size: 10px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .super-slim-field {
    max-width: 2rem;
  }
}
</style>
