<template>
  <dialog-content
    :dialog-title="dialogTitle"
    :on-submit="saveItem"
    :buttons="[
      { label: editable ? 'Cancel' : 'Close', onClick: closeDialog },
      {
        label: 'Manually Expand',
        onClick: openManualItemExpansionDialog,
        condition: dialogItem.hasStatusSkuResolutionFailed && !skuResolution,
        tooltip: 'Split Order Item Into Multiple Item SKUs'
      },
      {
        label: 'Review Order',
        onClick: openOrderUserReviewDialog,
        condition: dialogItem.hasStatusUserReview && !skuResolution
      },
      {
        label: 'Add New SKU',
        onClick: openNewSkuDialog,
        condition: dialogItem.hasStatusSkuResolutionFailed && skuResolution
      },
      {
        label: 'Cancel Item',
        onClick: cancelDialogItem,
        condition:
          (editable || skuResolution || (editableFields || [])?.length > 0) &&
          dialogItem.isAbleToBeCanceled,
        loading: dialogItemParentOrder === undefined
      },
      {
        label: skuResolution ? 'Save and Mark Resolved' : 'Save Changes',
        type: 'submit',
        condition: editable || skuResolution || (editableFields || [])?.length > 0,
        loading: loading
      },
      {
        label: 'Resolve SKU Conflict',
        onClick: openSkuResolutionDialog,
        condition: dialogItem.hasStatusSkuResolutionFailed && !skuResolution,
        tooltip: 'Resolve SKU Conflict'
      },
      {
        label: progressButton?.name ? progressButton?.name : '',
        onClick: progressButton?.action
          ? () => progressButton.action(dialogItem.withEarliestStatusOnly)
          : () => {},
        condition: !!progressButton && progressButton?.name !== 'Resolve SKU Conflict',
        tooltip: progressButton?.tooltip ? progressButton?.tooltip : ''
      }
    ]"
  >
    <order-item-inner-content-component
      v-model:item="dialogItem"
      :parent-order="dialogItemParentOrder"
      :sku-resolution="skuResolution"
      :editable-fields="editableFields"
      @loading="loading = $event as boolean"
    />
  </dialog-content>
</template>

<script lang="ts">
import DialogContent from '@/modal_dialogs/dialog_components/DialogContent.vue'
import OrderItemInnerContentComponent from '@/components/OrderItemInnerContentComponent.vue'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import {
  confirmCancelStatusCountDialog,
  confirmDialog,
  createUpdateditemListener,
  itemUpdated,
  newDialog,
  newSkuDialog,
  runFunctionWithLoadingSpinner,
  showSpinner
} from '@/composables'
import {
  type ItemDialogObject,
  LocalOrderItem,
  LocalOrder,
  LocalSku,
  api,
  LocalOrderItemStatusEnum,
  type AlertMessage
} from '@/services/DataServices'
import { throwError } from '@/services/ErrorHandler'
import { setGlobalUpdatingFlag } from '@/main'
import { MessageTypes } from '@/components/AlertMessageComponent.vue'

export default defineComponent({
  name: 'ViewItemDialogContent',
  emits: ['update:dialogOpen'],
  computed: {
    LocalOrderItemStatusEnum() {
      return LocalOrderItemStatusEnum
    },
    dialogTitle() {
      return (
        'Item ' + (this.dialogItem.id || '') + (this.skuResolution ? ' SKU Resolution' : ' Details')
      )
    }
  },
  components: {
    DialogContent,
    OrderItemInnerContentComponent
  },
  props: {
    dialogOpen: {
      // Receives dialogOpen value from v-model:dialog-open="dialogOpen"
      type: Boolean,
      required: true,
      default: true
    },
    itemProp: {
      type: Object as PropType<object | null>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    editableFields: {
      type: Array as PropType<string[] | null>,
      default: () => [
        'manufacturing_notes',
        'notes',
        'custom_text',
        'requires_post_processing',
        'post_processing_notes'
      ]
    }
  },
  data() {
    return {
      dialogItem: {} as LocalOrderItem,
      dialogItemParentOrder: undefined as LocalOrder | undefined,
      skuResolution: false as boolean,
      finalizedStatuses: [
        LocalOrderItemStatusEnum.Cancelled,
        LocalOrderItemStatusEnum.Complete,
        LocalOrderItemStatusEnum.ReviewBlocked
      ] as LocalOrderItemStatusEnum[],
      progressButton: undefined as any,
      loading: false as boolean
    }
  },
  methods: {
    async saveItem() {
      showSpinner(true)
      setGlobalUpdatingFlag(true)
      try {
        if (this.dialogItem.hasStatusSkuResolutionFailed) {
          await this.runSkuResolution()
        } else {
          await this.dialogItem.update()
        }
        this.closeDialog(this.dialogItem)
      } catch (error) {
        throwError('Error Saving Item', error)
      }
      // Update Local Order Item with selected SKU and close dialog
      setGlobalUpdatingFlag(false)
      showSpinner(false)
    },
    closeDialog(updatedItem?: LocalOrderItem) {
      // Updates Users Local Items Object
      if (updatedItem) {
        itemUpdated(updatedItem)
      }
      // Transmits dialogOpen value to <view-item-dialog-content v-model:dialog-open="dialogOpen" />
      this.$emit('update:dialogOpen', false)
    },
    openSkuResolutionDialog() {
      newDialog('viewItemDialog', {
        orderItem: this.dialogItem,
        order: this.dialogItemParentOrder,
        skuResolution: true
      })
    },
    openManualItemExpansionDialog() {
      this.closeDialog()
      newDialog('viewItemExpansionDialog', {
        orderItem: this.dialogItem,
        order: this.dialogItemParentOrder
      })
    },
    openOrderUserReviewDialog() {
      this.closeDialog()
      newDialog('viewOrderDialog', {
        order: this.dialogItemParentOrder,
        items: this.dialogItemParentOrder?.order_items,
        userReview: true
      })
    },
    openNewSkuDialog() {
      newSkuDialog({} as LocalSku)
    },
    async cancelDialogItem() {
      const cancelOrder: boolean = this.dialogItemParentOrder?.cancelableItems.length === 1
      let warning: AlertMessage | undefined = undefined
      if (cancelOrder) {
        warning = {
          message: 'Please Note: This is the final active Item.\nProceeding will cancel the Order.',
          messageType: MessageTypes.WARNING
        }
      }
      const message =
        'Are you sure you want to cancel item ' +
        (this.dialogItem.id || '') +
        (this.dialogItemParentOrder ? ' for order ' + (this.dialogItemParentOrder.id || '') : '') +
        '?'
      try {
        if (!this.dialogItem.isPreProvisioning && this.dialogItem.cancelableStatusCountsOrCount > 1)
          await this.cancelDialogItemWithCount(message, cancelOrder, warning)
        else await this.cancelDialogItemWithoutCount(message, cancelOrder, warning)
      } catch (e) {
        throwError('Error Canceling Order Item', e)
      }
    },
    async cancelDialogItemWithCount(message: string, cancelOrder: boolean, warning?: AlertMessage) {
      const result = await confirmCancelStatusCountDialog(this.dialogItem, message, warning)
      const cancelItemFunction = async () =>
        await this.dialogItem.cancelWithCount(result.statusCount, result.cancelEntireItem)
      cancelOrder = cancelOrder ? result.cancelEntireItem : false
      if (result.confirmed) await this.cancelItemOrOrder(cancelOrder, cancelItemFunction.bind(this))
    },
    async cancelDialogItemWithoutCount(
      message: string,
      cancelOrder: boolean,
      warning?: AlertMessage
    ) {
      const confirmed = await confirmDialog('Cancel Order Item?', message, warning)
      const cancelItemFunction = async () => await this.dialogItem.cancel()
      if (confirmed) await this.cancelItemOrOrder(cancelOrder, cancelItemFunction.bind(this))
    },
    async cancelItemOrOrder(cancelOrder: boolean, cancelItemFunction: Function): Promise<void> {
      const cancelFunction = async () => {
        if (cancelOrder) await this.dialogItemParentOrder?.cancel()
        else await cancelItemFunction()
      }
      await runFunctionWithLoadingSpinner(() => cancelFunction())
      this.closeDialog(this.dialogItem)
    },
    async runSkuResolution() {
      if (!this.dialogItem?.sku) {
        throwError(
          'SkuResolution Error',
          'No new Order Item SKU has been provided.\n\n' + JSON.stringify(this.dialogItem)
        )
        return
      }

      // Set unresolved_sku to new sku label if the sku was changed directly
      this.dialogItem.unresolved_sku = (await api.getSku(this.dialogItem.sku))?.label

      // Ensure sku remains null if updated
      this.dialogItem.sku = null

      // Update Item unresolved_sku then Resolve Sku
      await this.dialogItem.updateWithResolvedSku()
    },
    updateProgressButton() {
      this.progressButton = ((this.itemProp as ItemDialogObject).rowEndButtons || []).find(
        (button: any) => {
          return (
            button.displayCheckFunction(this.dialogItem.withEarliestStatusOnly) === true &&
            !(button.dontDisplayInDialog === true)
          )
        }
      )
    }
  },
  created() {
    try {
      const itemProp: ItemDialogObject = this.itemProp as ItemDialogObject
      if (!itemProp.orderItem?.id || !itemProp.orderItem?.order) {
        throwError('Error in ItemDialog props', 'Required Item Property Not Set.', this.dialogItem)
        this.closeDialog()
        return
      }
      this.dialogItem = new LocalOrderItem(itemProp.orderItem as LocalOrderItem)
      this.skuResolution = itemProp.skuResolution || false
      this.updateProgressButton()

      if (!itemProp.order) {
        api.getOrder(this.dialogItem.order as number).then((o) => (this.dialogItemParentOrder = o))
      } else {
        this.dialogItemParentOrder = itemProp.order
      }
      const itemUpdated = (event: Event) => {
        const item = (event as CustomEvent).detail.item as LocalOrderItem
        if (item.id === this.dialogItem.id) {
          this.dialogItem = item
          this.updateProgressButton()
        }
      }
      createUpdateditemListener(itemUpdated.bind(this))
    } catch (e) {
      throwError('Error in ItemDialog props.', e)
      this.closeDialog()
    }
  }
})
</script>
