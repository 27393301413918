<template>
  <Transition>
    <div class="loading-spinner-div" data-testid="loading-spinner-div" v-if="show">
      <img
        id="spinner"
        data-testid="loading-spinner"
        class="loading-spinner"
        src="/spinner.svg"
        alt="loading"
      />
      <span
        v-if="message"
        class="text-sm z-10 text-gray-800 drop-shadow-[0_0_3px_white] select-none font-medium"
        style="text-shadow: 0 0 3px white"
        >{{ message }}</span
      >
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { createSpinnerMessageListener } from '@/composables.ts'
export default defineComponent({
  name: 'SpinnerComponent',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    itemsToUpdate: {
      type: Number as PropType<number | null | undefined>,
      default: 0
    },
    itemsUpdated: {
      type: Number as PropType<number | null | undefined>,
      default: 0
    }
  },
  data() {
    return {
      customMessageToDisplay: '' as string
    }
  },
  computed: {
    message(): string | undefined {
      if (this.customMessageToDisplay && this.customMessageToDisplay.length > 0) {
        return this.customMessageToDisplay
      } else if (this.itemsToUpdate && this.itemsToUpdate > 0) {
        return `${this.itemsUpdated} of ${this.itemsToUpdate} items Processed...`
      } else {
        return undefined
      }
    }
  },
  created() {
    createSpinnerMessageListener((event: any) => {
      this.customMessageToDisplay = event?.detail?.message || ''
    })
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue: boolean) {
        if (!newValue) {
          this.customMessageToDisplay = ''
        }
      }
    }
  }
})
</script>

<style scoped>
.loading-spinner-div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgb(75 75 75 / 25%);
  z-index: 9999999;
  backdrop-filter: blur(2px);
  overflow: hidden;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  position: absolute;
  width: 20%;
  pointer-events: none;
  user-select: none;
  max-width: 10rem;
  min-width: 4rem;
  opacity: 0.7;
}

/* Transition animation classes */
.v-enter-active,
.v-leave-active {
  transition: all 0.35s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
