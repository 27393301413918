<template>
  <page-header text="Orders">
    <div id="buttons-div" class="justify-center align-baseline items-end flex gap-x-12 sm:gap-x-4">
      <div
        data-testid="import-orders-button"
        id="importOrdersButton"
        v-tooltip="'Sync With Online Orders'"
        class="cursor-pointer justify-center align-baseline items-end p-2 sm:p-2 flex w-12 h-12 sm:h-10 sm:w-10 text-gray-600 hover:text-gray-950 orderItemHeader hover:bg-slate-600/[0.1] rounded-3xl shadow-[1px_1px_4px_0_rgba(0,0,0,0.4)]"
        @click="importOnlineOrdersAndRefreshList()"
      >
        <arrow-path-icon
          style="padding-left: 1px; padding-bottom: 1px"
          class="h-full w-full"
          aria-hidden="true"
        />
      </div>

      <div
        id="createNewOrderButton"
        v-tooltip="'Create New Order'"
        class="cursor-pointer justify-center align-baseline items-end p-2 sm:p-2 flex w-12 h-12 sm:h-10 sm:w-10 text-gray-600 hover:text-gray-950 orderItemHeader hover:bg-slate-600/[0.1] rounded-3xl shadow-[1px_1px_4px_0_rgba(0,0,0,0.4)]"
        @click="openNewOrderDialog"
      >
        <document-plus-icon
          style="padding-left: 1px; padding-bottom: 1px"
          class="h-full w-full"
          aria-hidden="true"
        />
      </div>
    </div>
  </page-header>
  <table-component
    :retrieve-table-data-function="retrieveTableData"
    :update-on-change="updateKey"
    :unique-key="'id'"
    :process-field-function="processFieldFunction"
    :click-function="openOrderDialog"
    :hidden-fields="['updated', 'order_items']"
    :small-screen-hidden-fields="[
      'created',
      'notes',
      'order_import_source',
      'source_order_display_id'
    ]"
    :row-end-buttons="rowEndButtons"
    :row-end-top-buttons="rowEndTopButtons"
    :display-check-boxes="true"
    :long-text-field-keys="['notes']"
    :default-filter="{
      objectPropertyKeyName: filters[0].objectPropertyKeyName,
      objectPropertyValue: filters[0].propertyFilterValues[0]
    }"
    :filters-prop="filters"
    :default-active-column="'created'"
    :default-descending="true"
    :process-column-header-function="(h: string) => (h === 'id' ? 'ID' : h)"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { OrderStatusEnum } from '@/api'
import TableComponent from '@/components/TableComponent.vue'
import PageHeader from '@/components/PageHeader.vue'
import { errorHandler, throwError } from '@/services/ErrorHandler'
import {
  confirmDialog,
  createUpdateditemListener,
  createUpdatedOrderListener,
  newDialog,
  newErrorDialog,
  showSpinner,
  itemsUpdated,
  createUpdateditemsListener,
  runFunctionWithLoadingSpinner,
  setSpinnerMessage
} from '@/composables'
import { api, LocalOrder, getEnumAsArray, Filter } from '@/services/DataServices'
import type { TableRowEndButton, DataSet } from '@/services/DataServices'
import {
  DocumentPlusIcon,
  ArrowPathIcon,
  GiftIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  ChatBubbleLeftEllipsisIcon
} from '@heroicons/vue/24/outline'
import StatusLabelComponent from '@/components/StatusLabels/BaseStatusLabelComponent.vue'
import TableIconComponent from '@/components/TableIconComponent.vue'
import { getGlobalUpdatingFlag, setGlobalUpdatingFlag } from '@/main'
import { processHumanReadableDate } from '@/services/DateTimeServices.ts'

export default defineComponent({
  name: 'OrdersView',
  data() {
    return {
      updateKey: 0 as number,
      filters: [
        new Filter(
          'status',
          ['Active Statuses', ...getEnumAsArray(OrderStatusEnum)],
          0,
          undefined,
          1
        )
      ] as Filter[],
      openOrderDialog: ref((order: LocalOrder, userReview: boolean = false) => {
        newDialog('viewOrderDialog', { order: order, userReview: userReview })
      }),
      openNewOrderDialog: ref(() => {
        newDialog('viewOrderDialog', { order: {} }, true, false)
      }),
      rowEndButtons: [
        {
          label: '',
          tooltip: 'Order is Packed and Complete',
          icon: GiftIcon,
          action: this.markOrderComplete,
          displayCheckFunction: this.displayMarkOrderCompleteButton
        },
        {
          label: '',
          tooltip: 'Review Order',
          icon: MagnifyingGlassIcon,
          action: this.reviewOrder,
          displayCheckFunction: this.displayUserReviewButton
        }
      ] as TableRowEndButton[],
      rowEndTopButtons: [
        {
          label: '',
          tooltip: 'Update Status for all selected Orders',
          getToolTip: (orders: LocalOrder[]) => {
            if (!this.sameStatuses(orders)) {
              return 'Cannot update multiple Orders with different statuses'
            } else if (orders[0].status === OrderStatusEnum.InitError) {
              return 'Cannot update Orders in an Error State'
            } else if (this.containsCompletedOrders(orders)) {
              return 'Cannot update Completed or Canceled Orders'
            } else if (this.containsOrdersInProgress(orders)) {
              return 'Cannot update Orders with Items in progress'
            } else if (this.containsOrdersRequiringReview(orders)) {
              return 'Cannot batch update Orders requiring review'
            } else {
              return 'Update Statuses from ' + orders[0].status + ' to ' + orders[0].nextStatus()
            }
          },
          obscure: (orders: LocalOrder[]) => {
            return (
              !this.sameStatuses(orders) ||
              this.containsCompletedOrders(orders) ||
              this.containsOrdersInProgress(orders) ||
              this.containsOrdersRequiringReview(orders) ||
              orders[0].status === OrderStatusEnum.InitError ||
              orders[0].status === OrderStatusEnum.UserReview
            )
          },
          getIcon: (orders: LocalOrder[]) => {
            if (!this.sameStatuses(orders)) {
              return XMarkIcon
            } else {
              return this.displayMarkOrderCompleteButton(orders[0]) ? GiftIcon : XMarkIcon
            }
          },
          action: this.updateStatusForSelectedRows,
          displayCheckFunction: (orders: LocalOrder[]) => {
            return orders.length > 1
          }
        }
      ] as TableRowEndButton[]
    }
  },
  methods: {
    triggerTableRefresh() {
      if (!getGlobalUpdatingFlag()) {
        this.updateKey++
      }
    },
    async retrieveTableData(
      maxItems: number,
      startIndex: number,
      stringFilter?: string,
      orderBy?: string,
      filterArray?: Filter[]
    ): Promise<DataSet<LocalOrder>> {
      if (orderBy && orderBy.includes('order_import_source')) {
        orderBy = orderBy.replace('order_import_source', 'order_import_source__name')
      }
      const status = filterArray?.find((f) => f.objectPropertyKeyName === 'status')?.currentFilter()
      return await api.getOrdersSet(maxItems, startIndex, stringFilter, orderBy, status)
    },
    async importOnlineOrdersAndRefreshList() {
      showSpinner(true)
      setSpinnerMessage('Importing New Orders...')
      await api.importOnlineOrders()
      setSpinnerMessage('Syncing Completed Orders...')
      await api.syncCompleteOnlineOrders()
      setSpinnerMessage('Finishing Up...')
      itemsUpdated()
      showSpinner(false)
    },
    error(error: any) {
      errorHandler(error)
    },
    // prettier-ignore
    processFieldFunction(fieldValue: any, columnName: string, row?: any) { // eslint-disable-line
      // Formats expected objects as readable string(s)
      const column = columnName.toLowerCase().replace(/_/g, ' ')
      if (['created', 'date', 'updated'].includes(column)) {
        // Is date
        if('updated' === column) return processHumanReadableDate(fieldValue)
        return processHumanReadableDate(fieldValue, true)
      }

      if (column === 'status') {
        return { component: StatusLabelComponent, propObject: { label: fieldValue, shrink: true } }
      }

      if (column === 'order import source') {
        const importSourceText: string | undefined = fieldValue?.name
        if(!importSourceText && fieldValue) {
          throwError('Error in Orders View', 'Order Import Source Missing.', JSON.stringify(row))
        }
        return importSourceText || ''
      }

      if (['notes'].includes(column)) {
        // Notes Field, replace with icon
        if (fieldValue && fieldValue.length > 0) {
          return {
            component: TableIconComponent,
            propObject: {
              iconComponent: ChatBubbleLeftEllipsisIcon,
              tooltip: fieldValue,
              class:
                  'w-7 h-7 inline-block stroke-[0.125rem] text-orange-500 drop-shadow-[0.15rem_0.15rem_0.075rem_rgba(0,0,0,0.2)]'
            }
          }
        } else {
          return ''
        }
      }

      return fieldValue
    },
    async markOrderComplete(order: LocalOrder) {
      await runFunctionWithLoadingSpinner(() => order.complete())
    },
    async reviewOrder(order: LocalOrder) {
      this.openOrderDialog(order, true)
    },
    displayMarkOrderCompleteButton(order: LocalOrder) {
      return order.hasStatus(OrderStatusEnum.ReadyForDispatch)
    },
    displayUserReviewButton(order: LocalOrder) {
      return order.status === OrderStatusEnum.UserReview
    },
    sameStatuses(orders: LocalOrder[]): boolean {
      return orders.every((order: LocalOrder) => order.status === orders[0].status)
    },
    containsCompletedOrders(orders: LocalOrder[]): boolean {
      return orders.some((order: LocalOrder) => {
        return order.hasStatusInList([OrderStatusEnum.Complete, OrderStatusEnum.Cancelled])
      })
    },
    containsOrdersInProgress(orders: LocalOrder[]): boolean {
      return orders.some((order: LocalOrder) => {
        return order.hasStatusInList([OrderStatusEnum.Provisioning])
      })
    },
    containsOrdersRequiringReview(orders: LocalOrder[]): boolean {
      return (
        this.sameStatuses(orders) &&
        (orders[0].status === OrderStatusEnum.UserReview ||
          orders[0].status === OrderStatusEnum.SkuResolutionFailed)
      )
    },
    async updateStatusForSelectedRows(orders: LocalOrder[]) {
      if (!this.sameStatuses(orders)) {
        newErrorDialog(
          'Status Update Error',
          'All selected orders must have the same status to progress.'
        )
      } else if (this.containsOrdersInProgress(orders)) {
        newErrorDialog('Status Update Error', 'Cannot update Orders with Items in progress.')
      } else if (this.containsCompletedOrders(orders)) {
        newErrorDialog('Status Update Error', 'Cannot update Completed or Canceled Orders.')
      } else if (orders[0].status === OrderStatusEnum.InitError) {
        newErrorDialog('Status Update Error', 'Cannot update Orders in an Error State.')
      } else if (this.containsOrdersRequiringReview(orders)) {
        newErrorDialog('Status Update Error', 'Cannot batch update Orders requiring review.')
      } else {
        if (
          await confirmDialog(
            'Update ' + orders.length + ' Orders',
            'Update Statuses from ' + orders[0].status + ' to ' + orders[0].nextStatus() + '?'
          )
        ) {
          showSpinner(true, orders.length)
          setGlobalUpdatingFlag(true)
          for (const order of orders) {
            await order.complete()
          }
          setGlobalUpdatingFlag(false)
          showSpinner(false)
          itemsUpdated()
        }
      }
    }
  },
  created() {
    // Update Users Local Variables On Change
    createUpdatedOrderListener(this.triggerTableRefresh.bind(this))
    createUpdateditemListener(this.triggerTableRefresh.bind(this))
    createUpdateditemsListener(this.triggerTableRefresh.bind(this))
  },
  components: {
    PageHeader,
    TableComponent,
    DocumentPlusIcon,
    ArrowPathIcon
  }
})
</script>
