<template>
  <div
    :class="[$props.class, { 'py-1': !orderUserReview }]"
    class="overflow-auto break-words max-h-fit px-1"
  >
    <!-- SKU Resolution: -->
    <div
      v-if="item?.hasStatusSkuResolutionFailed"
      class="inline-block max-w-md pb-4 text-center text-gray-700 text-sm font-medium leading-6"
    >
      <p v-if="!skuResolution">The SKU imported with this Order Item could not be resolved.</p>
      <p v-if="skuResolution">Please assign the correct SKU for this item.</p>
      <p v-if="skuResolution">
        Alternatively, add the SKU to the system first, then retry order import.
      </p>
      <p v-if="!skuResolution">Please run SKU Resolution using the button below.</p>
    </div>

    <!--  Order Item Count: -->
    <number-input
      v-if="componentItem?.count"
      :label="'Quantity'"
      :number="itemCount"
      @update:number="componentItem.count = $event.toString()"
      :editable="(!orderUserReview || componentItem.isNew) && (editable || isEditable('quantity'))"
      :min="1"
      class="font-semibold"
    />

    <!-- Parent Order Information For Reference: -->
    <!--  Customer Name: -->
    <text-input
      v-if="skuResolution && parentOrder && parentOrder.customer_name?.length > 0"
      :label="'Customer Name'"
      :text="parentOrder?.customer_name || ''"
      :editable="false"
    />
    <!--  Order Source: -->
    <text-input
      v-if="skuResolution && parentOrder?.order_import_source"
      :label="'Order Source'"
      :text="parentOrder?.order_import_source?.name || ''"
      :editable="false"
    />
    <!--  Order Source Order ID: -->
    <text-input
      v-if="skuResolution && parentOrder && (parentOrder?.source_order_display_id || '').length > 0"
      :label="'Source Order ID'"
      :text="parentOrder?.source_order_display_id || ''"
      :editable="false"
    />
    <!--  Order: -->
    <text-input
      v-if="!editable && !skuResolution && !orderUserReview"
      :label="'Related Order'"
      :text="componentItem.order"
    />
    <!-- Order Notes: -->
    <text-field-input
      v-if="parentOrder?.id && (editable || isEditable('notes')) && !orderUserReview"
      :label="'Order Notes'"
      :text="parentOrder.notes || ''"
    />

    <!-- Erroneous SKU: -->
    <text-input
      v-if="item?.hasStatusSkuResolutionFailed"
      :label="'Erroneous SKU'"
      :text="
        '&quot;' +
        ((componentItem?.unresolved_sku || '').length > 0
          ? componentItem.unresolved_sku
          : '( SKU Not Set )') +
        '&quot;'
      "
      :editable="false"
      :input-style-override="
        'font-style: italic;' +
        ((componentItem?.unresolved_sku || '').length > 0
          ? ' color: #b91c1c;'
          : ' opacity: 0.5; font-style: italic;')
      "
    />
    <!--  SKU: -->
    <list-input
      :update-key="updateKey"
      id="itemSkuDropDown"
      v-if="skuResolution || editable || isEditable('sku')"
      class="w-full"
      :editable="editable || skuResolution"
      :label="skuResolution ? 'Select Correct SKU:' : 'Item SKU'"
      :options="getSkusForDropDown"
      :selected="itemSku || null"
      @update:selected="componentItem.sku = ($event as LocalSku).id as number"
      :object-label-key-name="'label'"
      :custom-label-get-function="customLabelGetFunction"
      :max-height="'17rem'"
    />
    <text-input
      v-if="item?.sku && !editable && !skuResolution && !orderUserReview"
      :label="'SKU ID'"
      :text="componentItem.sku?.toString()"
    />
    <button-input
      v-if="item?.isNew || skuResolution"
      :action="addNewSkuToItem"
      :button-text="'Add New SKU Directly To Item'"
    />
    <!-- SKU Name: -->
    <text-field-input
      v-if="itemSku && itemSku.description && !editable && !skuResolution && !orderUserReview"
      :label="'SKU Description'"
      :text="itemSku?.description"
      :editable="false"
    />
    <!-- SKU Label: -->
    <text-input
      v-if="itemSku && itemSku.label && !editable && !skuResolution"
      :label="'SKU Label'"
      :text="itemSku?.label"
      :editable="false"
    />
    <!-- SKU Design Type: -->
    <text-input
      v-if="itemSku && itemSku.design_type && !skuResolution && !orderUserReview"
      :label="'SKU Design Type'"
      :text="itemSku?.design_type"
      :editable="false"
    />
    <!-- Material: -->
    <text-input
      v-if="materialName && !skuResolution && !orderUserReview"
      :label="'Material'"
      :text="materialName"
      :editable="false"
    />
    <!--  Custom Text: -->
    <text-input
      v-if="itemSku && itemSku?.design_type === SkuDesignTypeEnum.Variable && !skuResolution"
      :required="
        itemSku?.design_type === SkuDesignTypeEnum.Variable && !componentItem.isPostProvisioning
      "
      :label="'Custom Text'"
      v-model:text="componentItem.custom_text"
      :editable="
        editable || isEditable('custom_text') || (orderUserReview && !item?.isPostProvisioning)
      "
    />
    <!-- ITEM Notes: -->
    <text-field-input
      v-if="!(skuResolution && componentItem.notes?.length === 0)"
      :label="'Item Notes'"
      v-model:text="componentItem.notes"
      :editable="
        ((editable || isEditable('notes')) && !skuResolution) ||
        (orderUserReview && !item?.isPostProvisioning)
      "
    />
    <!-- ITEM MANUFACTURING Notes: -->
    <text-field-input
      v-if="!(skuResolution && componentItem.manufacturing_notes?.length === 0)"
      :label="'Manufacturing Notes'"
      v-model:text="componentItem.manufacturing_notes"
      :editable="
        ((editable || isEditable('manufacturing_notes')) && !skuResolution) ||
        (orderUserReview && !item?.isPostProvisioning)
      "
    />
    <!--  Item Requires Post Processing: -->
    <check-box-input
      v-if="!skuResolution"
      :label="'Post Processing Required'"
      v-model:checked="componentItem.requires_post_processing"
      :show-yes-no="false"
      :editable="
        (editable || isEditable('requires_post_processing') || orderUserReview) &&
        !item?.isPostProvisioning
      "
    />
    <!--  Post Processing Notes: -->
    <text-field-input
      v-if="componentItem.requires_post_processing === true && (!skuResolution || orderUserReview)"
      :label="'Post Processing Notes'"
      v-model:text="componentItem.post_processing_notes"
      :editable="
        (editable || isEditable('requires_post_processing') || orderUserReview) &&
        !item?.isPostProvisioning
      "
    />
    <!--  Order Item Statuses: -->
    <order-item-status-count-read-only-input
      v-if="!skuResolution && !editable && !orderUserReview"
      :item="componentItem"
    />
    <!--  Date Created: -->
    <text-input
      v-if="!editable || skuResolution"
      :label="'Date Created'"
      :text="processHumanReadableDate(componentItem.created, true)"
    />
    <!--  Last Updated: -->
    <text-input
      v-if="!editable && !skuResolution && !orderUserReview"
      :label="'Last Updated'"
      :text="processHumanReadableDate(componentItem.updated)"
    />
    <!--  Item Customization Performed: -->
    <check-box-input
      v-if="!editable && !skuResolution && !orderUserReview"
      :label="'Customization Has Been Performed'"
      v-model:checked="componentItem.customization_has_been_performed"
    />
    <!--  Item Is PreStocked: -->
    <check-box-input
      v-if="showPreStockedCheckbox"
      :editable="!item?.isPostProvisioning"
      :label="'Item Pre-Stocked'"
      v-model:checked="prestocked"
      :tooltip="'Pre-Manufactured Stock Is Available To Be Assigned To This Item.'"
    />
    <!--  Item PreStocked Count: -->
    <number-input
      v-if="showPreStockedCheckbox && prestocked"
      :label="'Pre-Stocked Count'"
      :number="componentItem.count ?? 1"
      @update:number="prestockedCount = $event"
      :editable="componentItem?.hasCount && componentItem.countAsNumber > 1"
      :min="1"
      :max="componentItem?.hasCount ? componentItem.countAsNumber : 1"
    />
    <!--  Item Faults: -->
    <text-field-input v-if="itemFaults" :label="'Previous Item Faults'" :text="itemFaults" />
    <!--  Design File Download: -->
    <button-input
      v-if="!item?.isNew && item?.has_design_file"
      :action="downloadDesignFile"
      :button-text="'Download'"
      :label="'Design File'"
    />

    <!--  Order Items Internal Buttons: -->
    <div
      v-if="enableInternalButtons && componentItem.hasStatusSkuResolutionFailed"
      class="mb-0 pt-4 mt-0 flex justify-evenly gap-x-4"
    >
      <button
        v-tooltip="'Resolve SKU Conflict'"
        v-if="componentItem.hasStatusSkuResolutionFailed && !skuResolution"
        type="button"
        @click="openSkuResolutionDialog()"
        class="w-auto global-button inline-flex justify-center rounded-md bg-indigo-600 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Resolve SKU Conflict
      </button>
      <button
        v-tooltip="'Split Order Item Into Multiple Item SKUs'"
        v-if="componentItem.hasStatusSkuResolutionFailed && !skuResolution"
        type="button"
        @click="openManualItemExpansionDialog()"
        class="w-auto global-button inline-flex justify-center rounded-md bg-indigo-600 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Manually Expand
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import TextInput from '@/components/inputComponents/TextInput.vue'
import TextFieldInput from '@/components/inputComponents/TextFieldInput.vue'
import CheckBoxInput from '@/components/inputComponents/CheckBoxInput.vue'
import {
  LocalSku,
  LocalOrderItem,
  LocalOrder,
  getSkusForDropDown,
  api,
  getInitialSku,
  LocalFault,
  stringLabelCompare
} from '@/services/DataServices'
import type { Sku } from '@/api'
import ListInput from '@/components/inputComponents/ListInput.vue'
import { SkuDesignTypeEnum } from '@/api'
import OrderItemStatusCountReadOnlyInput from '@/components/inputComponents/OrderItemStatusCountReadOnlyInput.vue'
import {
  createNewItemSkuListener,
  createUpdatedSkusListener,
  newDialog,
  newSkuDialog
} from '@/composables'
import NumberInput from '@/components/inputComponents/NumberInput.vue'
import ButtonInput from '@/components/inputComponents/ButtonInput.vue'
import { throwError } from '@/services/ErrorHandler'
import { processHumanReadableDate } from '@/services/DateTimeServices.ts'

export default defineComponent({
  name: 'OrderItemInnerContentComponent',
  emits: ['update:item', 'loading', 'update:preStockedItem'],
  components: {
    ButtonInput,
    NumberInput,
    OrderItemStatusCountReadOnlyInput,
    ListInput,
    CheckBoxInput,
    TextFieldInput,
    TextInput
  },
  props: {
    class: {
      default: '',
      type: String
    },
    editable: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object as PropType<LocalOrderItem | null>,
      required: true
    },
    parentOrder: {
      type: Object as PropType<LocalOrder | null>
    },
    skuResolution: {
      type: Boolean,
      default: false
    },
    orderUserReview: {
      type: Boolean,
      default: false
    },
    editableFields: {
      type: Array as PropType<string[] | null>,
      default: () => []
    },
    enableInternalButtons: {
      type: Boolean as PropType<boolean | null>,
      default: false
    },
    showPreStockedCheckbox: {
      type: Boolean as PropType<boolean | null>,
      default: false
    }
  },
  data() {
    return {
      updateKey: 0 as number,
      itemSku: undefined as LocalSku | undefined,
      componentItem: {} as LocalOrderItem,
      itemFaults: undefined as string | undefined,
      customLabelGetFunction: (value: Sku) => {
        return value.description ? value.label + '  -  ' + value.description : value.label
      },
      prestocked: false as boolean,
      prestockedCount: 1 as number
    }
  },
  created() {
    const updateTable = () => this.updateKey++
    createUpdatedSkusListener(updateTable.bind(this))
    const updateItemSKU: Function = (sku: LocalSku) => (this.componentItem.sku = sku.id)
    createNewItemSkuListener(this.componentItem.id, updateItemSKU.bind(this))
    this.retrieveItemFaults()
  },
  computed: {
    SkuDesignTypeEnum() {
      return SkuDesignTypeEnum
    },
    LocalSku() {
      return LocalSku
    },
    materialName(): string | undefined {
      return this.itemSku?.material?.label ?? undefined
    },
    itemCount(): number {
      return Number(this.componentItem?.count || '1')
    }
  },
  methods: {
    async setItemSkuIfExists() {
      this.$emit('loading', true)
      if (this.componentItem?.sku) {
        this.itemSku = await api.getSku(this.componentItem?.sku as number)
      } else if (this.skuResolution) {
        this.itemSku = await getInitialSku()
        this.componentItem.sku = this.itemSku.id
      }
      this.$emit('loading', false)
    },
    getSkusForDropDown,
    processHumanReadableDate,
    isEditable(fieldName: string): boolean {
      return (
        this.editable ||
        this.editableFields?.find((f) => stringLabelCompare(f, fieldName)) !== undefined ||
        false
      )
    },
    openSkuResolutionDialog() {
      newDialog(
        'viewItemDialog',
        {
          orderItem: this.componentItem as LocalOrderItem,
          order: this.parentOrder,
          skuResolution: true
        },
        false
      )
    },
    openManualItemExpansionDialog() {
      newDialog(
        'viewItemExpansionDialog',
        {
          orderItem: this.componentItem as LocalOrderItem,
          order: this.parentOrder,
          skuResolution: true
        },
        false
      )
    },
    emitPrestockedUpdate(): void {
      this.$emit('update:preStockedItem', {
        item: this.componentItem,
        prestocked: this.prestocked,
        count: this.prestockedCount
      })
    },
    addNewSkuToItem(): void {
      newSkuDialog({} as LocalSku, this.componentItem.id)
    },
    downloadDesignFile(): void {
      api.downloadDesignFiles(this.componentItem)
    },
    async retrieveItemFaults() {
      if (!this.componentItem.isNew && this.componentItem?.id) {
        try {
          const faults: LocalFault[] = await api.getFaultsForItemID(this.componentItem?.id || -1)
          if (faults.length > 0) {
            this.itemFaults = faults.map((f, i) => this.getFaultText(f, i)).join('\n\n')
          }
        } catch (e) {
          throwError('Error Retrieving Item Faults.', e)
        }
      }
    },
    getFaultText(f: LocalFault, i: number): string {
      return `Fault ${i + 1} (${processHumanReadableDate(f.created)}): "${f.description}"`
    }
  },
  watch: {
    prestocked: {
      handler() {
        this.emitPrestockedUpdate()
      },
      immediate: true
    },
    prestockedCount: {
      handler() {
        this.emitPrestockedUpdate()
      },
      immediate: true
    },
    item: {
      handler(newValue: LocalOrderItem, oldValue: any) {
        if (newValue && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.componentItem = new LocalOrderItem(this.item as LocalOrderItem)
          if (oldValue?.sku !== newValue?.sku) this.setItemSkuIfExists()
        }
      },
      deep: true,
      immediate: true
    },
    componentItem: {
      handler(newValue: LocalOrderItem) {
        if (newValue && JSON.stringify(newValue) !== JSON.stringify(this.item)) {
          this.$emit('update:item', new LocalOrderItem(newValue))
        }
      },
      deep: true,
      immediate: true
    },
    showPreStockedCheckbox: {
      handler(show: boolean | null) {
        if (!show) {
          this.prestocked = false /* prestocked watcher will automatically emit change */
        }
      },
      immediate: true
    }
  }
})
</script>
